import { Injectable, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of, switchMap } from 'rxjs';
import { selectConsumer } from 'src/app/redux/user/user.selectors';
import { Results } from 'src/app/shared/models/generics';
import { FetchMenusParams, Menu } from 'src/app/shared/models/menus';

import { MenusService } from './menus.service';

@Injectable({ providedIn: 'root' })
export class OfflineMenusService implements MenusService {
  private readonly store = inject(Store);

  fetchMenus = (
    params: FetchMenusParams | { own: `true` } = { own: `true` },
  ): Observable<Results<Menu>> => {
    const menus = JSON.parse(localStorage.getItem('menus')) as Record<
      string,
      Results<Menu>
    >;
    return this.store.pipe(
      select(selectConsumer),
      switchMap((consumer) => of(menus[consumer?.uuid])),
    );
  };

  fetchMenu = (params: {
    menu: string;
    date: string;
  }): Observable<Results<Menu>> => {
    return of();
  };
}
