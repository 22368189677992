import { Component, DestroyRef, OnDestroy, inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { selectDietsItems } from 'src/app/redux/diets/diets.selectors';
import {
  fetchAutocompleteSearch,
  showSnackbarMessage,
} from 'src/app/redux/global/global.actions';
import {
  selectGlobalLanguage,
  selectShowConsumerInfo,
  selectShowConsumerInfoDetail,
} from 'src/app/redux/global/global.selectors';
import {
  selectCurrentMenu,
  selectNextMenu,
  selectPickedDate,
  selectPrevMenu,
} from 'src/app/redux/menus/menus.selectors';
import { selectOfflineModeValue } from 'src/app/redux/offline-mode/offline-mode.selectors';
import { LANGUAGES } from 'src/app/shared/constants/languages';
import { AnyAccessType } from 'src/app/shared/models/consumers';
import { Menu } from 'src/app/shared/models/menus';
import { OrganisationFeatures } from 'src/app/shared/models/user';

import {
  applyDefaultOrder,
  fetchProductSheet,
  fetchSource,
  ordersSelectionStateChanged,
  repeatOrdersFetched,
  sendOrdersAndNext,
  sendOrdersAndPrev,
  sendOrdersAndRedirect,
  setMenuDiets,
} from '../../redux/orders/orders.actions';
import {
  selectCurrentMenuDiets,
  selectCurrentMenuSource,
  selectFirstOrderOfMenu,
  selectMenuLanguage,
  selectOrders,
  selectOrdersRouterExtras,
  selectRepeatOrders,
  selectSaveOrderButtonState,
  selectShowHiddenOrders,
} from '../../redux/orders/orders.selectors';
import {
  selectConsumer,
  selectFeature,
  selectIsAgent,
  selectIsManager,
  selectQuantityFieldIncrement,
  selectQuantityInputField,
  selectShowPortionSize,
  selectShowQuantity,
  selectSimpleConsumer,
  selectTerminal,
  selectUserAllowOrderAllergies,
  selectUserAllowOrderIntolerances,
} from '../../redux/user/user.selectors';
import { ManagableItem, SelectFieldModel } from '../../shared/models/misc';
import {
  ManagableOrder,
  Order,
  SetOrdersItem,
} from '../../shared/models/orders';
import { setMenuLanguage } from './../../redux/orders/orders.actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AsyncPipe } from '@angular/common';
import { SelectOrdersComponent } from './select-orders/select-orders.component';
import { setPrevMenu, setNextMenu } from 'src/app/redux/menus/menus.actions';

@Component({
  selector: 'win-orders',
  templateUrl: './orders.container.html',
  styleUrls: ['../menus/menus.container.scss', './orders.container.scss'],
  standalone: true,
  imports: [SelectOrdersComponent, AsyncPipe],
})
export class OrdersContainer implements OnDestroy {
  private readonly destroyRef = inject(DestroyRef);
  private readonly store = inject(Store);
  private readonly transloco = inject(TranslocoService);

  managableItem: ManagableItem;

  currentMenu$ = this.store.pipe(select(selectCurrentMenu));
  selectedDate$ = this.store.pipe(select(selectPickedDate));
  orders$ = this.store.pipe(select(selectOrders));
  repeatOrders$ = this.store.pipe(select(selectRepeatOrders));
  currentMenuLanguage$ = this.store.pipe(select(selectMenuLanguage));
  showHiddenOrders$ = this.store.pipe(select(selectShowHiddenOrders));
  extraItem$ = this.store.pipe(select(selectOrdersRouterExtras));
  isAgent$ = this.store.pipe(select(selectIsAgent));
  isManager$ = this.store.pipe(select(selectIsManager));
  isTerminal$ = this.store.pipe(select(selectTerminal));
  firstOrder$ = this.store.pipe(select(selectFirstOrderOfMenu));
  featureConsistency$ = this.store.pipe(
    select(selectFeature(OrganisationFeatures.consistency)),
  );
  featureTexture$ = this.store.pipe(
    select(selectFeature(OrganisationFeatures.texture)),
  );
  prevMenu$ = this.store.pipe(select(selectPrevMenu));
  nextMenu$ = this.store.pipe(select(selectNextMenu));
  currentMenuSource$ = this.store.pipe(select(selectCurrentMenuSource));
  consumer$ = this.store.pipe(select(selectConsumer));
  unauthConsumer$ = this.store.pipe(select(selectSimpleConsumer));
  selectShowConsumerInfo$ = this.store.pipe(select(selectShowConsumerInfo));
  selectShowConsumerInfoDetail$ = this.store.pipe(
    select(selectShowConsumerInfoDetail),
  );
  currentDiets$ = this.store.pipe(select(selectCurrentMenuDiets));
  saveOrderLoading$ = this.store.pipe(select(selectSaveOrderButtonState));
  dietsEditableField$ = this.store.pipe(select(selectDietsItems)).pipe(
    switchMap((diets) => of(diets).pipe(withLatestFrom(this.globalLanguage$))),
    map(([diets, lang]) => {
      return diets?.map(
        (diet) =>
          ({
            id: diet.identifier,
            name: diet[lang]
              ? diet[lang]
              : diet[Object.keys(LANGUAGES).find((l) => diet[l])],
            location: diet.location,
          }) as SelectFieldModel,
      );
    }),
  );
  globalLanguage$ = this.store.pipe(select(selectGlobalLanguage));
  isOffline$ = this.store.pipe(select(selectOfflineModeValue));
  isUserAllowOrderAllergies$ = this.store.pipe(
    select(selectUserAllowOrderAllergies),
  );
  isUserAllowOrderIntolerances$ = this.store.pipe(
    select(selectUserAllowOrderIntolerances),
  );
  showQuantity$ = this.store.select(selectShowQuantity);
  showPortionSize$ = this.store.select(selectShowPortionSize);
  currentOrders$ = this.store.pipe(
    select(selectOrders),
    filter((orders) => !!orders && orders.length > 0),
  );
  currentOrdersRepeat$ = this.store.pipe(
    select(selectRepeatOrders),
    filter((orders) => !!orders && orders.length > 0),
  );
  selectQuantityInputField$ = this.store.pipe(select(selectQuantityInputField));
  selectQuantityFieldIncrement$ = this.store.pipe(
    select(selectQuantityFieldIncrement),
  );

  constructor() {
    this.extraItem$
      .pipe(
        filter((v) => !!v),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((item: ManagableItem) => (this.managableItem = item));
  }

  sendItems(items: {
    items: SetOrdersItem[];
    previousOrders: Order[] | null;
  }): void {
    this.store.dispatch(
      sendOrdersAndRedirect({
        ...items,
        editedItem: this.managableItem,
      }),
    );
  }

  sendOrdersAndNext(items: {
    items: SetOrdersItem[];
    previousOrders: Order[] | null;
  }): void {
    this.store.dispatch(
      sendOrdersAndNext({
        ...items,
        editedItem: this.managableItem,
      }),
    );
  }
  sendOrdersAndPrev(items: {
    items: SetOrdersItem[];
    previousOrders: Order[] | null;
  }): void {
    this.store.dispatch(
      sendOrdersAndPrev({
        ...items,
        editedItem: this.managableItem,
      }),
    );
  }

  ordersSelected(): void {
    this.store.dispatch(ordersSelectionStateChanged({ state: true }));
  }

  fetchProductSheet(token): void {
    this.store.dispatch(fetchProductSheet({ token: token['token'] }));
  }

  applyDefaultOrder(data: {
    consumer: AnyAccessType | ManagableOrder;
    selectedDiets: string[];
  }): void {
    this.store.dispatch(
      applyDefaultOrder({
        orderData: data.consumer,
        selectedDiets: data.selectedDiets,
      }),
    );
  }

  applyDiets(selectedDiets: string[]): void {
    this.store.dispatch(setMenuDiets({ selectedDiets }));
  }

  showUnselectSnackMessage() {
    this.store.dispatch(
      showSnackbarMessage({
        message: this.transloco.translate('manage-orders.selection-removed'),
        button: this.transloco.translate('manage-orders.undo'),
        duration: 5000,
        action: () => {
          const targets = Array.from(
            document.querySelectorAll('[data-updated=true]'),
          );
          targets.forEach((el) => {
            el.setAttribute('data-ordered', 'true');
            el.removeAttribute('data-updated');
          });
        },
      }),
    );
  }

  applyTranslation(data: { translation: string; menu: Menu }) {
    this.store.dispatch(setMenuLanguage({ language: data.translation }));
    this.store.dispatch(
      fetchSource({
        menu: data.menu,
        dietRefetch: false,
        nonRegularTranslation: data.translation,
      }),
    );
  }

  searchAutocomplete = ({ value }: { value: string }) => {
    this.store.dispatch(
      fetchAutocompleteSearch({
        field: 'description',
        value: value ?? '',
        model: 'order',
      }),
    );
  };

  clearRepeatOrders(): void {
    this.store.dispatch(
      repeatOrdersFetched({
        repeatOrders: null,
      }),
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(ordersSelectionStateChanged({ state: false }));
    this.store.dispatch(setMenuLanguage({ language: null }));
    this.store.dispatch(setPrevMenu({ menu: null }));
    this.store.dispatch(setNextMenu({ menu: null }));
  }
}
