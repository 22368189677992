<mat-card appearance="outlined" class="card mat-elevation-z2">
  <img class="card-img-top" src="img/menusCard3.png" alt="" />
  @if (!unauthInvalidUrlMessage) {
    <p class="title">
      {{
        'login.terminal.consumer-title.unauthenticated-order-consumer'
          | transloco
      }}
    </p>
    @if (!spinnerState) {
      <win-form-with-errors
        [formGroup]="consumerForm"
        [formId]="consumerFormId"
      >
        <div class="fields">
          @if (aggregatedOrderFeature === true) {
            <ngx-otp-input
              #nameCodeInput
              [options]="otpInputConfig"
              (otpComplete)="onNameCodeCompleted($event)"
            ></ngx-otp-input>
            <p
              class="example-text"
              [innerHTML]="
                'login.terminal.consumer-form.name_example' | transloco
              "
            ></p>
          } @else {
            <p class="label">
              {{ 'login.terminal.consumer-form.first_name' | transloco }}:
            </p>
            <ngx-otp-input
              #firstNameCodeInput
              [options]="otpInputConfig"
              (otpComplete)="onCodeCompleted($event, 'first_name')"
            ></ngx-otp-input>
            <p
              class="example-text"
              [innerHTML]="
                'login.terminal.consumer-form.first_name_example' | transloco
              "
            ></p>

            @if (showLastName) {
              <p class="label">
                {{ 'login.terminal.consumer-form.last_name' | transloco }}:
              </p>
              <ngx-otp-input
                #lastNameCodeInput
                [options]="otpInputConfig"
                (otpComplete)="onCodeCompleted($event, 'last_name')"
              ></ngx-otp-input>
              <p
                class="example-text"
                [innerHTML]="
                  'login.terminal.consumer-form.last_name_example' | transloco
                "
              ></p>
            }
          }
        </div>
      </win-form-with-errors>
    } @else {
      <div class="spinner-wrapper">
        <mat-spinner
          class="loading-spinner"
          mode="indeterminate"
          [diameter]="48"
        ></mat-spinner>
      </div>
    }
  } @else {
    @if (unauthInvalidUrlMessage) {
      <div class="error-message">
        {{ 'login.terminal.consumer-title.invalid-key-error' | transloco }}
      </div>
    }
  }
</mat-card>
