export class AMQPQueue {
  constructor(channel, name) {
    this.channel = channel;
    this.name = name;
  }
  bind(exchange, routingKey = "", args = {}) {
    return new Promise((resolve, reject) => {
      this.channel.queueBind(this.name, exchange, routingKey, args).then(() => resolve(this)).catch(reject);
    });
  }
  unbind(exchange, routingKey = "", args = {}) {
    return new Promise((resolve, reject) => {
      this.channel.queueUnbind(this.name, exchange, routingKey, args).then(() => resolve(this)).catch(reject);
    });
  }
  publish(body, properties = {}) {
    return new Promise((resolve, reject) => {
      this.channel.basicPublish("", this.name, body, properties).then(() => resolve(this)).catch(reject);
    });
  }
  subscribe({
    noAck = true,
    exclusive = false,
    tag = "",
    args = {}
  } = {}, callback) {
    return this.channel.basicConsume(this.name, {
      noAck,
      exclusive,
      tag,
      args
    }, callback);
  }
  unsubscribe(consumerTag) {
    return new Promise((resolve, reject) => {
      this.channel.basicCancel(consumerTag).then(() => resolve(this)).catch(reject);
    });
  }
  delete() {
    return new Promise((resolve, reject) => {
      this.channel.queueDelete(this.name).then(() => resolve(this)).catch(reject);
    });
  }
  get({
    noAck = true
  } = {}) {
    return this.channel.basicGet(this.name, {
      noAck
    });
  }
  purge() {
    return this.channel.queuePurge(this.name);
  }
}
