import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'win-menu-card',
  templateUrl: './menu-card.component.html',
  styleUrls: ['./menu-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, MatIconModule, NgOptimizedImage, NgTemplateOutlet],
})
export class MenuCardComponent {
  @Input() description: string;
  @Input() img: string;
  @Input() external = false;
  @Input() link: string;
  @Input() title: string;
}
