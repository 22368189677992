import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import {
  authenticationFeatureKey,
  authenticationReducer,
  AuthenticationState,
} from './authentication/authentication.reducer';
import {
  coursesFeatureKey,
  coursesReducer,
  CoursesState,
} from './courses/courses.reducer';
import {
  dietsFeatureKey,
  dietsReducer,
  DietsState,
} from './diets/diets.reducer';
import {
  globalFeatureKey,
  globalReducer,
  GlobalState,
} from './global/global.reducer';
import {
  menusFeatureKey,
  menusReducer,
  MenusState,
} from './menus/menus.reducer';
import {
  offlineModeFeatureKey,
  offlineModeReducer,
  OfflineModeState,
} from './offline-mode/offline-mode.reducer';
import {
  ordersFeatureKey,
  ordersReducer,
  OrdersState,
} from './orders/orders.reducer';
import { routerFeatureKey } from './router/router.selectors';
import { userFeatureKey, userReducer, UserState } from './user/user.reducer';

export interface IState {
  [authenticationFeatureKey]: AuthenticationState;
  [coursesFeatureKey]: CoursesState;
  [dietsFeatureKey]: DietsState;
  [globalFeatureKey]: GlobalState;
  [menusFeatureKey]: MenusState;
  [offlineModeFeatureKey]: OfflineModeState;
  [ordersFeatureKey]: OrdersState;
  [routerFeatureKey]: RouterReducerState<any>;
  [userFeatureKey]: UserState;
}

export const reducer: ActionReducerMap<IState> = {
  [authenticationFeatureKey]: authenticationReducer,
  [coursesFeatureKey]: coursesReducer,
  [dietsFeatureKey]: dietsReducer,
  [globalFeatureKey]: globalReducer,
  [menusFeatureKey]: menusReducer,
  [offlineModeFeatureKey]: offlineModeReducer,
  [ordersFeatureKey]: ordersReducer,
  [routerFeatureKey]: routerReducer,
  [userFeatureKey]: userReducer,
};
