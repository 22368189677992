export class AMQPMessage {
  constructor(channel) {
    this.exchange = "";
    this.routingKey = "";
    this.properties = {};
    this.bodySize = 0;
    this.body = null;
    this.bodyPos = 0;
    this.deliveryTag = 0;
    this.consumerTag = "";
    this.redelivered = false;
    this.channel = channel;
  }
  bodyToString() {
    if (this.body) {
      if (typeof Buffer !== "undefined") return Buffer.from(this.body).toString();else return new TextDecoder().decode(this.body);
    } else {
      return null;
    }
  }
  bodyString() {
    return this.bodyToString();
  }
  ack(multiple = false) {
    return this.channel.basicAck(this.deliveryTag, multiple);
  }
  nack(requeue = false, multiple = false) {
    return this.channel.basicNack(this.deliveryTag, requeue, multiple);
  }
  reject(requeue = false) {
    return this.channel.basicReject(this.deliveryTag, requeue);
  }
  cancelConsumer() {
    return this.channel.basicCancel(this.consumerTag);
  }
}
