import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { environment } from 'src/environments/environment';
import { MenuCardComponent } from './menu-card/menu-card.component';

@Component({
  selector: 'win-menus-cards',
  templateUrl: './menus-cards.component.html',
  styleUrls: ['./menus-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MenuCardComponent, TranslocoPipe],
})
export class MenusCardsComponent {
  @Input() isKdsUser: boolean;
  @Input() isManager: boolean;
  @Input() isServiceUser: boolean;
  @Input() showThirdCard: boolean;
  @Output() scrollToMenus = new EventEmitter<void>();

  kdsLink = environment.kdsUrl;
  serviceLink = environment.serviceUrl;
}
