<section class="wrapper">
  @if ((routeData$ | async)?.confirm) {
  <win-confirm
    [spinnerState]="confirmSpinnerState$ | async"
    (confirm)="onConfirm($event)"
  ></win-confirm>
  } @else {
  <h1 class="mat-h1">{{ "password-reset.reset" | transloco }}</h1>
  <p class="mat-body subtitle">{{ "password-reset.title" | transloco }}</p>
  <win-reset
    [spinnerState]="spinnerState$ | async"
    (resetEmail)="onReset($event)"
  ></win-reset>
  }

  <div class="links mat-body">
    <a data-cy="login-link" routerLink="/login"
      >{{'app.links.to-login' | transloco}}</a
    >
    <a data-cy="register-link" routerLink="/registration"
      >{{'app.links.register' | transloco}}</a
    >
  </div>
</section>
