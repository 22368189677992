<span
  [matTooltip]="tooltip"
  matTooltipPosition="below"
  matTooltipClass="win-tooltip"
>
  <button
    mat-flat-button
    class="nav-button"
    [disabled]="disabled"
    (click)="navigate.emit()"
  >
    <mat-icon class="action-icon">keyboard_arrow_left</mat-icon>
    {{ label }}
  </button>
</span>
