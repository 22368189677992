import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DietsState, dietsFeatureKey } from './diets.reducer';

export const selectState = createFeatureSelector<DietsState>(dietsFeatureKey);

export const selectDiets = createSelector(selectState, (state) => state);

export const selectDietsItems = createSelector(
  selectDiets,
  (state) => state?.items,
);
