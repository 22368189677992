<h1 class="mat-h1">{{ userFullName$ | async }}</h1>
<p class="mat-body">{{ 'menus.verify-email.title' | transloco }}</p>

<p class="mat-body-strong">
  {{ 'menus.verify-email.sent' | transloco: (userEmail$ | async) }}
</p>
<button
  mat-button
  class="action-button verify-email"
  color="primary"
  routerLink="/settings"
>
  {{ 'menus.verify-email.update' | transloco }}
</button>

<p class="mat-body">{{ 'menus.verify-email.description' | transloco }}</p>
@if (emailResent) {
  <p class="mat-body resent">{{ 'menus.verify-email.resent' | transloco }}</p>
} @else {
  <button
    mat-button
    color="primary"
    class="action-button resend-email"
    data-cy="resend-email"
    (click)="resendEmail()"
  >
    {{ 'menus.verify-email.resend-link' | transloco }}
  </button>
}
