import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatButtonModule } from '@angular/material/button';
import { FieldWithErrorsComponent } from '../../../shared/components/field-with-errors/field-with-errors.component';
import { FormWithErrorsComponent } from '../../../shared/components/form-with-errors/form-with-errors.component';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'win-reset',
  templateUrl: './reset.component.html',
  styleUrls: [
    '../../../shared/styles/authentication.scss',
    './reset.component.scss',
  ],
  standalone: true,
  imports: [
    FieldWithErrorsComponent,
    FormWithErrorsComponent,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    TranslocoPipe,
  ],
})
export class ResetComponent implements OnInit {
  private readonly fb = inject(FormBuilder);

  @Input() spinnerState = false;
  @Output() resetEmail = new EventEmitter<{ email: string }>();

  resetPasswordForm: FormGroup;

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.resetPasswordForm = this.fb.group({
      email: [
        '',
        {
          validators: [Validators.required, Validators.email],
        },
      ],
    });
  }

  onSubmit(): void {
    if (this.resetPasswordForm.valid) {
      this.resetEmail.emit(this.resetPasswordForm.value);
    }
  }
}
