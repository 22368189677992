<div class="wrapper">
  <h1 class="mat-h1">{{ 'password-reset.reset' | transloco }}</h1>
  <mat-card
    appearance="outlined"
    class="card mat-elevation-z2"
    data-cy="password-confirm-form"
  >
    <win-form-with-errors
      class="form"
      [formGroup]="passwordsForm"
      formId="confirm-new-password"
    >
      <div class="fields">
        <win-field-with-errors
          icon="lock"
          type="password"
          [autocomplete]="'new-password'"
          [label]="'registration.password1.placeholder' | transloco"
          translationKey="registration.password1.errors"
          formControlName="new_password1"
          [required]="true"
        ></win-field-with-errors>
        <win-field-with-errors
          icon="lock"
          type="password"
          [autocomplete]="'new-password'"
          [label]="'registration.password2.placeholder' | transloco"
          formControlName="new_password2"
          translationKey="registration.password2.errors"
          [required]="true"
        ></win-field-with-errors>
      </div>
      <button
        class="submit-button"
        mat-flat-button
        color="primary"
        type="submit"
        data-cy="submit-reset"
        [disabled]="spinnerState || !passwordsForm.valid"
        (click)="onSubmit()"
      >
        @if (spinnerState) {
          <span>
            <mat-spinner
              class="loading-spinner"
              mode="indeterminate"
              [diameter]="24"
            ></mat-spinner>
          </span>
        }
        {{ 'password-reset.reset' | transloco }}
      </button>
    </win-form-with-errors>
  </mat-card>
</div>
