<win-select-orders
  [consumer]="consumer$ | async"
  [consumerUnauth]="unauthConsumer$ | async"
  [currentMenuSource]="currentMenuSource$ | async"
  [currentMenuDiets]="currentDiets$ | async"
  [currentOrders]="currentOrders$ | async"
  [currentOrdersRepeat]="currentOrdersRepeat$ | async"
  [editedItem]="managableItem"
  [featureConsistency]="featureConsistency$ | async"
  [featureTexture]="featureTexture$ | async"
  [firstOrder]="firstOrder$ | async"
  [isAgent]="isAgent$ | async"
  [isManager]="isManager$ | async"
  [isOffline]="isOffline$ | async"
  [isTerminal]="isTerminal$ | async"
  [isUserAllowOrderAllergies]="isUserAllowOrderAllergies$ | async"
  [isUserAllowOrderIntolerances]="isUserAllowOrderIntolerances$ | async"
  [lang]="globalLanguage$ | async"
  [menuLang]="(currentMenuLanguage$ | async) ?? (globalLanguage$ | async)"
  [diets]="dietsEditableField$ | async"
  [menu]="currentMenu$ | async"
  [nextMenu]="nextMenu$ | async"
  [orders]="orders$ | async"
  [prevMenu]="prevMenu$ | async"
  [repeatOrders]="repeatOrders$ | async"
  [saveOrderLoading]="saveOrderLoading$ | async"
  [selectedDate]="selectedDate$ | async"
  [showConsumerInfo]="selectShowConsumerInfo$ | async"
  [showConsumerInfoDetail]="selectShowConsumerInfoDetail$ | async"
  [showHidden]="showHiddenOrders$ | async"
  [showQuantity]="showQuantity$ | async"
  [showPortionSize]="showPortionSize$ | async"
  [quantityFieldIncrement]="selectQuantityFieldIncrement$ | async"
  [quantityFieldInput]="selectQuantityInputField$ | async"
  (applyDefaultOrder)="applyDefaultOrder($event)"
  (applyDiets)="applyDiets($event)"
  (applyTranslation)="applyTranslation($event)"
  (fetchProductSheet)="fetchProductSheet($event)"
  (searchAutocomplete)="searchAutocomplete($event)"
  (selectionChanged)="ordersSelected()"
  (sendOrdersAndNextEvent)="sendOrdersAndNext($event)"
  (sendOrdersAndPrevEvent)="sendOrdersAndPrev($event)"
  (sendOrdersEvent)="sendItems($event)"
  (showUnselectSnackMessage)="showUnselectSnackMessage()"
  (clearRepeatOrders)="clearRepeatOrders()"
></win-select-orders>
