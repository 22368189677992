@if (productSheetLoading$ | async) {
  <div class="spinner-container">
    <mat-spinner class="loading-spinner" color="primary"></mat-spinner>
  </div>
}

@if ((productSheetLoading$ | async) === false) {
  @if (productSheetSource$ | async) {
    <div [innerHtml]="productSheetSource$ | async | safe"></div>
  }
}
