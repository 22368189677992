import {
  Directive,
  AfterViewInit,
  ElementRef,
  inject,
  input,
} from '@angular/core';
import { A11y, Navigation } from 'swiper/modules';
import { SwiperContainer, register } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types';

@Directive({
  selector: '[winSwiper]',
  standalone: true,
})
export class SwiperDirective implements AfterViewInit {
  private readonly el = inject<ElementRef<SwiperContainer>>(ElementRef);

  config = input.required<SwiperOptions>();

  defaultConfig: SwiperOptions = {
    modules: [Navigation, A11y],
    breakpoints: {
      700: {
        slidesPerView: 2,
      },
      1040: {
        slidesPerView: 3,
      },
      1500: {
        slidesPerView: 4,
      },
      1920: {
        slidesPerView: 5,
      },
    },
    spaceBetween: 16,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: false,
    scrollbar: {
      hide: true,
    },
  };
  private readonly swiperElement: SwiperContainer;

  constructor() {
    register();
    this.swiperElement = this.el.nativeElement;
  }

  ngAfterViewInit() {
    Object.assign(this.swiperElement, {
      ...this.defaultConfig,
      ...this.config(),
    });
    this.swiperElement?.initialize?.();
  }
}
