import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { LoginForm } from 'src/app/shared/models/authentication';
import { TranslocoPipe } from '@jsverse/transloco';
import { RouterLink } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { FieldWithErrorsComponent } from '../../../shared/components/field-with-errors/field-with-errors.component';
import { FormWithErrorsComponent } from '../../../shared/components/form-with-errors/form-with-errors.component';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'win-simple-login',
  templateUrl: './simple-login.component.html',
  styleUrls: [
    '../../../shared/styles/authentication.scss',
    './simple-login.component.scss',
  ],
  standalone: true,
  imports: [
    MatCardModule,
    FormWithErrorsComponent,
    ReactiveFormsModule,
    FieldWithErrorsComponent,
    MatButtonModule,
    MatProgressSpinnerModule,
    RouterLink,
    TranslocoPipe,
  ],
})
export class SimpleLoginComponent {
  private readonly formBuilder = inject(FormBuilder);

  loginForm: FormGroup;

  @Input() spinnerState = false;
  @Output() login = new EventEmitter<LoginForm>();

  constructor() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
        ],
      ],
    });
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      this.login.emit(this.loginForm.value);
    }
  }
}
