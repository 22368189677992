<div class="wrapper">
  <div #rfidCard class="card-wrapper">
    <mat-card appearance="outlined" class="card mat-elevation-z2">
      <win-form-with-errors
        class="form"
        [formGroup]="terminalForm"
        formId="live-order-view"
      >
        <div class="fields">
          <win-field-with-errors
            class="identifier-field"
            type="text"
            [label]="'login.terminal.placeholder' | transloco"
            translationKey="login.terminal.errors"
            formControlName="rfid"
            [showProgressBar]="loading"
            [autofocus]="true"
            [autoclear]="true"
            [autocomplete]="'off'"
            icon="money"
            (keyup.enter)="initTerminalLogin()"
            [required]="true"
          >
            @if (terminalForm.get('rfid')?.value) {
              <button
                type="button"
                class="suffixTemplate"
                mat-icon-button
                (click)="resetRfid()"
              >
                <mat-icon>clear</mat-icon>
              </button>
            }
          </win-field-with-errors>
        </div>
      </win-form-with-errors>
    </mat-card>
  </div>
</div>

<mat-card
  appearance="outlined"
  class="orders"
  [class.no-orders-fetched]="ordersFetched === false"
  [class.no-order-card]="ordersFetched === true && !orders?.length"
>
  @if (orders?.length) {
    @if (privacy !== 1) {
      <div class="item title">
        {{ 'live-order-view.name' | transloco }}
        {{
          orders?.[0]?.consumer_detail
            ? orders?.[0]?.consumer_detail?.name
            : orders?.[0]?.created_by_detail?.name
        }}
      </div>
    }
    @for (order of orders; track order; let i = $index) {
      <win-selected-order
        class="selected-order"
        [order]="order"
        [orderPrev]="orders[i - 1]"
      ></win-selected-order>
    }
  } @else {
    @if (ordersFetched === false) {
      <mat-icon class="qr-icon">qr_code_scanner</mat-icon>
      <p class="mat-body-1 no-orders-text">
        {{ 'live-order-view.rfid-description' | transloco }}
      </p>
    } @else {
      <span>{{ 'live-order-view.no-order' | transloco }}</span>
    }
  }
</mat-card>

<div class="footer-wrapper">
  @if (privacy && privacy !== 1) {
    <div class="description">
      {{
        'live-order-view.today'
          | transloco: { date: today | date: 'mediumDate' : undefined : lang }
      }}
    </div>
    <div class="count">
      <span class="footer-count-text"
        >{{
          'live-order-view.orders-count'
            | transloco: { count: ordersDoneCount ?? 0 }
        }}
      </span>
      <span class="footer-count-text">{{
        'live-order-view.orders-remaining'
          | transloco: { count: ordersRemainingCount ?? 0 }
      }}</span>
    </div>
  }
</div>

<audio #audioSuccess>
  <source src="audio/success.mp3" type="audio/mp3" />
</audio>
<audio #audioFail>
  <source src="audio/fail.mp3" type="audio/mp3" />
</audio>
<audio #audioClick>
  <source src="audio/click.mp3" type="audio/mp3" />
</audio>
