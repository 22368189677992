import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { OnlineMenusService } from 'src/app/shared/services/menus/online.menus.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

import { handleHttpError, showSnackbarMessage } from '../global/global.actions';
import {
  addCreatedCourse,
  createCourse,
  deleteCourse,
  fetchCourses,
  removeDeletedCourse,
  setCourses,
  setCoursesDataLoading,
  setUpdatedCourse,
  updateCourse,
} from './courses.actions';

@Injectable()
export class CoursesEffects {
  private readonly actions$ = inject(Actions);
  private readonly menuService = inject(OnlineMenusService);
  private readonly router = inject(Router);
  private readonly store = inject(Store);
  private readonly transloco = inject(TranslocoService);
  private readonly utilsService = inject(UtilsService);

  createCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCourse),
      switchMap(({ payload: { payload, redirect } }) =>
        this.menuService.createCourses(payload).pipe(
          mergeMap((data) => {
            this.utilsService.resetCourse.next();
            if (redirect) this.router.navigate([`menus`]);
            return [
              addCreatedCourse({ payload: data }),
              showSnackbarMessage({
                message: this.transloco.translate(
                  this.CONSUMER_CREATED_SUCCESS,
                ),
                button: this.transloco.translate(this.GOT_IT),
              }),
            ];
          }),
          catchError((error: unknown) => [
            handleHttpError({ error, formId: 'add-course' }),
          ]),
        ),
      ),
    ),
  );

  deleteCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCourse),
      switchMap(({ courseId }) =>
        this.menuService.deleteCourses(courseId).pipe(
          mergeMap(() => [
            setCoursesDataLoading({ loading: false }),
            removeDeletedCourse({ courseId: courseId }),
            showSnackbarMessage({
              message: this.transloco.translate(this.COURSE_DELETED),
            }),
          ]),
          catchError((error: unknown) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  fetchCourses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCourses),
      tap(() => {
        this.store.dispatch(setCoursesDataLoading({ loading: true }));
      }),
      switchMap(() =>
        this.menuService.fetchCourses().pipe(
          mergeMap((courses) => [
            setCoursesDataLoading({ loading: false }),
            setCourses({ items: courses }),
          ]),
          catchError((error: unknown) => [
            setCoursesDataLoading({ loading: false }),
            handleHttpError({ error }),
          ]),
        ),
      ),
    ),
  );

  updateCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCourse),
      switchMap(({ payload: { payload, courseId } }) =>
        this.menuService.updateCourses(payload, courseId).pipe(
          mergeMap((data) => {
            this.utilsService.resetCourse.next();
            this.router.navigate([`menus`]);
            return [
              setUpdatedCourse({ course: data, courseId: courseId }),
              showSnackbarMessage({
                message: this.transloco.translate(
                  this.CONSUMER_UPDATED_SUCCESS,
                ),
                button: this.transloco.translate(this.GOT_IT),
              }),
            ];
          }),
          catchError((error: unknown) => [
            handleHttpError({ error, formId: 'add-course' }),
          ]),
        ),
      ),
    ),
  );

  readonly COURSE_DELETED = `menus.build-new-skills.deleted`;
  readonly CONSUMER_CREATED_SUCCESS = `menus.build-new-skills.success`;
  readonly CONSUMER_UPDATED_SUCCESS = `menus.build-new-skills.updated`;
  readonly GOT_IT = 'menus.build-new-skills.got-it';
}
