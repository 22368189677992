import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Diet, DietParams } from 'src/app/shared/models/diets';

import { API_CONFIG } from '../../../app.config';
import { DietsService } from './diets.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Injectable({ providedIn: 'root' })
export class OnlineDietsService implements DietsService {
  private readonly utilsService = inject(UtilsService);

  private readonly dietsEP = `${API_CONFIG.orderTakingApi}/diets/`;

  fetchDiets(params: DietParams): Observable<Diet[]> {
    return this.utilsService.get<Diet[]>(this.dietsEP, {
      ...params,
      pagination: 'off',
    });
  }
}
