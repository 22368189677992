import { Component, ViewEncapsulation, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  selectProductSheetLoadingState,
  selectProductSheetSource,
} from 'src/app/redux/orders/orders.selectors';
import { SafePipe } from '../../../../../shared/pipes/safe.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'win-product-sheet-dialog',
  templateUrl: './product-sheet-dialog.component.html',
  styleUrls: ['./product-sheet-dialog.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: true,
  imports: [MatProgressSpinnerModule, AsyncPipe, SafePipe],
})
export class ProductSheetDialogComponent {
  private readonly store = inject(Store);

  productSheetLoading$ = this.store.pipe(
    select(selectProductSheetLoadingState),
  );
  productSheetSource$ = this.store.pipe(select(selectProductSheetSource));
}
