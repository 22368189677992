import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostBinding,
  OnInit,
  inject,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  consumerNameTerminalLogin,
  consumerTerminalLogin,
  login,
  roomLogin,
  validateRoom,
} from 'src/app/redux/authentication/authentication.actions';
import { selectGlobalLanguage } from 'src/app/redux/global/global.selectors';
import {
  selectOrders,
  selectOrdersDoneCount,
  selectOrdersRemainingCount,
  selectOrdersWereFetched,
} from 'src/app/redux/orders/orders.selectors';

import { setOfflineConsumer } from '../../redux/offline-mode/offline-mode.actions';
import { fetchOrdersOfConsumer } from '../../redux/orders/orders.actions';
import {
  roomDetail,
  selectFeature,
  selectIsManager,
  selectLiveOrderView,
} from '../../redux/user/user.selectors';
import { OrganisationFeatures } from '../../shared/models/user';
import {
  roomDetailsLoadingBar,
  selectUnauthAggregatedOrderFeature,
  selectUnauthInvalidUrlMessage,
  selectLoginButtonState,
  selectRoomLoginBar,
  selectShowSimpleLoginForm,
  selectTerminalPrivacy,
  selectTerminalType,
  selectUnauthSpinner,
} from './../../redux/authentication/authentication.selectors';
import { fetchOrdersCount } from './../../redux/orders/orders.actions';
import { LoginForm } from './../../shared/models/authentication';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoPipe } from '@jsverse/transloco';
import { TerminalLoginComponent } from './terminal-login/terminal-login.component';
import { SimpleLoginComponent } from './simple-login/simple-login.component';
import { UnauthenticatedLoginComponent } from './unauthenticated-login/unauthenticated-login.component';
import { LiveOrderViewComponent } from './live-order-view/live-order-view.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'win-login',
  templateUrl: './login.container.html',
  styleUrls: ['./login.container.scss'],
  standalone: true,
  imports: [
    LiveOrderViewComponent,
    UnauthenticatedLoginComponent,
    SimpleLoginComponent,
    TerminalLoginComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class LoginContainer implements OnInit {
  private readonly store = inject(Store);
  private readonly activatedRouter = inject(ActivatedRoute);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly dateAdapter = inject<DateAdapter<Date>>(DateAdapter);
  private readonly destroyRef = inject(DestroyRef);

  gloablLanguage$ = this.store.pipe(select(selectGlobalLanguage));
  showSimpleLoginForm$ = this.store.pipe(select(selectShowSimpleLoginForm));
  terminalType$ = this.store.pipe(select(selectTerminalType));
  terminalPrivacy$ = this.store.pipe(select(selectTerminalPrivacy));
  roomDetails$ = this.store.pipe(select(roomDetail));
  isManager$ = this.store.pipe(select(selectIsManager));
  ordersRemainingCount$ = this.store.pipe(select(selectOrdersRemainingCount));
  ordersDoneCount$ = this.store.pipe(select(selectOrdersDoneCount));
  roomLoginBar$ = this.store.pipe(select(selectRoomLoginBar));
  roomDetailsLoadingBar$ = this.store.pipe(select(roomDetailsLoadingBar));
  showLiveOrder$ = this.store.pipe(select(selectLiveOrderView));
  rfidEnabled$ = this.store.pipe(
    select(selectFeature(OrganisationFeatures.rfid)),
  );
  aggregatedOrderTakingEnabled$ = this.store.pipe(
    select(selectFeature(OrganisationFeatures.aggregated_ordertaking)),
  );
  spinnerState$ = this.store.pipe(select(selectLoginButtonState));

  orders$ = this.store.pipe(select(selectOrders));
  ordersFetched$ = this.store.pipe(select(selectOrdersWereFetched));

  unauthAggregatedOrderTakingEnabled$ = this.store.pipe(
    select(selectUnauthAggregatedOrderFeature),
  );
  unauthInvalidUrlMessage$ = this.store.pipe(
    select(selectUnauthInvalidUrlMessage),
  );
  unauthSpinnerState$ = this.store.pipe(select(selectUnauthSpinner));

  unauthenticatedOrder = false;

  @HostBinding('class') get HeadingClass() {
    let showSimpleLoginForm = false;
    this.store
      .pipe(
        select(selectShowSimpleLoginForm),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((ShowSimpleLoginForm) => {
        if (ShowSimpleLoginForm) {
          showSimpleLoginForm = true;
        }
      });
    if (!showSimpleLoginForm || this.unauthenticatedOrder) {
      return 'background';
    } else {
      return '';
    }
  }

  ngOnInit(): void {
    if (
      this.activatedRouter.snapshot.queryParams.key &&
      this.activatedRouter.snapshot.queryParams.auth_room
    ) {
      this.unauthenticatedOrder = true;
      this.cdr.detectChanges();
    }
  }

  fetchOrdersCount = () => {
    this.store.dispatch(
      fetchOrdersCount({
        date: this.dateAdapter.format(new Date(), 'yyyy-MM-dd'),
      }),
    );
  };

  onLogin(data: LoginForm): void {
    this.store.dispatch(login({ ...data, formId: 'simple-login' }));
  }

  onTerminalLogin({
    rfid,
    room,
    formId,
  }: {
    rfid?: string;
    room?: string;
    formId: string;
  }): void {
    this.store.dispatch(
      setOfflineConsumer({
        payload: rfid,
      }),
    );
    this.store.dispatch(roomLogin({ room, rfid, formId }));
  }

  onConsumerTerminalLogin({ uuid }: { uuid: string }): void {
    this.store.dispatch(consumerTerminalLogin({ uuid }));
  }

  onConsumerNameTerminalLogin(data) {
    this.store.dispatch(consumerNameTerminalLogin(data));
  }

  validateRoom({ room, formId }: { room: string; formId: string }): void {
    this.store.dispatch(validateRoom({ room, formId }));
  }

  rfidChanged(rfid: string): void {
    this.store.dispatch(fetchOrdersOfConsumer({ rfid }));
  }
}
