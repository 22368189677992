import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'win-save-stripe',
  templateUrl: './save-stripe.component.html',
  styleUrls: ['./save-stripe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SaveStripeComponent {}
