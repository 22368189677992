import { Component, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  confirmPasswordReset,
  resetPassword,
} from 'src/app/redux/authentication/authentication.actions';
import { selectResetButtonState } from 'src/app/redux/authentication/authentication.selectors';
import { selectRouteData } from 'src/app/redux/router/router.selectors';
import { TranslocoPipe } from '@jsverse/transloco';
import { RouterLink } from '@angular/router';
import { ResetComponent } from './reset/reset.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'win-reset-password',
  templateUrl: './reset-password.container.html',
  styleUrls: [
    '../login/simple-login/simple-login.component.scss',
    './reset-password.container.scss',
  ],
  standalone: true,
  imports: [
    ConfirmComponent,
    ResetComponent,
    RouterLink,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class ResetPasswordContainer {
  private readonly store = inject(Store);

  confirmSpinnerState$ = this.store.pipe(select(selectResetButtonState));
  routeData$ = this.store.pipe(select(selectRouteData));
  spinnerState$ = this.store.pipe(select(selectResetButtonState));

  readonly confirmFormId = 'confirm-new-password';
  readonly resetFormId = 'reset-password';

  onReset({ email }: { email: string }): void {
    this.store.dispatch(resetPassword({ email, formId: this.resetFormId }));
  }

  onConfirm(data: { new_password1: string; new_password2: string }): void {
    this.store.dispatch(
      confirmPasswordReset({ payload: data, formId: this.confirmFormId }),
    );
  }
}
