import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { LANGUAGES } from 'src/app/shared/constants/languages';
import {
  Order,
  REPEAT_OPTIONS,
  RepeatOrder,
  RepeatOrderOptionData,
} from 'src/app/shared/models/orders';
import { TranslocoPipe } from '@jsverse/transloco';
import { RepeatOrderComponent } from '../repeat-order/repeat-order.component';

@Component({
  selector: 'win-selected-order',
  templateUrl: './selected-order.component.html',
  styleUrls: ['./selected-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RepeatOrderComponent, TranslocoPipe],
})
export class SelectedOrderComponent {
  @Input() set lang(l: keyof typeof LANGUAGES) {
    this._lang = l;
  }
  get lang(): keyof typeof LANGUAGES {
    return this._lang;
  }
  _lang: keyof typeof LANGUAGES;
  @Input() checkin: string;
  @Input() checkout: string;
  @Input() disabledRepeatOptions: REPEAT_OPTIONS[] = [];
  @Input() isDailyMenu: boolean;
  @Input() isOffline: boolean;
  @Input() order: Order | RepeatOrder;
  @Input() orderPrev: Order | RepeatOrder;
  @Input() repeatOrder: RepeatOrder;
  @Input() showRepeat: boolean;
  @Input() subtleUI: boolean;

  @Output() repeatChanged = new EventEmitter<{
    data: RepeatOrderOptionData;
    url: string | null;
  }>();
}
