import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { API_CONFIG } from 'src/app/app.config';
import { Consumer } from 'src/app/shared/models/consumers';
import { OfflineSetOrders } from 'src/app/shared/models/orders';

import { selectOfflineModeValue } from '../../../redux/offline-mode/offline-mode.selectors';
import { Results } from '../../models/generics';
import { OfflineStorageService } from '../offline-storage/offline-storage.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Injectable({ providedIn: 'root' })
export class OfflineModeService {
  private readonly store = inject(Store);
  private readonly offlineStorageService = inject(OfflineStorageService);
  private readonly utilsService = inject(UtilsService);

  private readonly consumersEP = `${API_CONFIG.orderTakingApi}/consumers/?is_active=true`;
  private readonly CREATED_ORDERS_NAME = 'created-orders';
  private readonly PENDING_ORDERS_NAME = 'pending-orders';

  private orders: Record<string, OfflineSetOrders> =
    this.offlineStorageService.getValues(this.CREATED_ORDERS_NAME) || {};

  clearOrders = () => (this.orders = {});

  getOrders = () => this.orders;

  setOrders = (uuid: string, order: any) => (this.orders[uuid] = order);

  isOffline = () => this.store.select(selectOfflineModeValue);

  fetchConsumers = (url?: string) =>
    this.utilsService.get<Results<Consumer>>(url || this.consumersEP);

  getOfflineOrdersData(): Record<string, OfflineSetOrders> {
    const orders = this.offlineStorageService.getValues(
      this.CREATED_ORDERS_NAME,
    );
    const pendingOrders = this.offlineStorageService.getValues(
      this.PENDING_ORDERS_NAME,
    );
    return { ...orders, ...pendingOrders };
  }

  setOfflineData(key: string, data: any): void {
    this.offlineStorageService.setValues(key, data);
  }

  setOfflineOrdersData(orders: any): void {
    this.setOfflineData(this.CREATED_ORDERS_NAME, orders);
  }

  setUnsubmittedOrders(unsubmittedOrders: any): void {
    this.setOfflineData(this.PENDING_ORDERS_NAME, unsubmittedOrders);
  }
}
