import { Injectable, inject } from '@angular/core';

import { OfflineModeService } from '../offline-mode/offline-mode.service';
import { OfflineOrdersService } from './offline.orders.service';
import { OnlineOrdersService } from './online.orders.service';
import { OrdersService } from './orders.service';

@Injectable({ providedIn: 'root' })
export class OrdersServiceBuilder {
  private readonly offlineModeService = inject(OfflineModeService);
  private readonly onlineOrdersService = inject(OnlineOrdersService);
  private readonly offlineOrdersService = inject(OfflineOrdersService);

  getService(): OrdersService {
    let isOffline = false;
    this.offlineModeService
      .isOffline()
      .subscribe((value) => {
        isOffline = value;
      })
      .unsubscribe();
    return isOffline ? this.offlineOrdersService : this.onlineOrdersService;
  }
}
