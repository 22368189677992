@if (currentError) {
<div class="error-wrapper">
  <h1>Ooooh ...</h1>
  <h4>{{ currentError.error | transloco }}</h4>
  <img
    class="error-image"
    width="400"
    src="img/page_not_found.png"
    alt="Error"
  />
  <button mat-raised-button color="primary" (click)="navigate()">
    {{ 'navigation.back' | transloco }}
  </button>
</div>
} @else {
<div class="redirection-wrapper">
  <mat-spinner color="primary"></mat-spinner>
  @if (redirectionUrl$ | async) {
  <p class="redirect-link">
    {{ 'navigation.manual-redirect' | transloco }}
    <a [href]="redirectionUrl$ | async">{{ 'shared.here' | transloco }}</a>.
  </p>
  }
</div>
}
