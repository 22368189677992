import { Action, createReducer, on } from '@ngrx/store';

import {
  setOfflineConsumer,
  setOfflineDataDownloading,
  setOfflineDate,
  setOfflineError,
  setOfflineModeValue,
  setOfflineOrders,
  setOfflineOrdersSubmitting,
  toggleOfflineMode,
} from './offline-mode.actions';
import { OfflineOrder } from 'src/app/shared/services/offline-mode/offline-orders.service';

export const offlineModeFeatureKey = 'offlineMode';

export interface OfflineModeState {
  value: boolean;
  downloading: boolean;
  submitting: boolean;
  consumer: string;
  date: string;
  orders: Record<string, OfflineOrder>;
  orderCount: number;
  hasError: boolean;
}

export const initialState: OfflineModeState = {
  value: false,
  downloading: false,
  submitting: false,
  consumer: null,
  date: null,
  orders: {},
  orderCount: 0,
  hasError: false,
};

const getOfflineOrdersCount = (obj: Record<string, OfflineOrder>): number => {
  return Object.entries(obj).filter(([, value]) => value?.offline).length;
};

const _offlineModeReducer = createReducer(
  initialState,
  on(toggleOfflineMode, (state) => ({
    ...state,
    value: !state.value,
  })),
  on(setOfflineConsumer, (state, { payload }) => ({
    ...state,
    consumer: payload,
  })),
  on(setOfflineDate, (state, { payload }) => ({
    ...state,
    date: payload ? payload.toISOString().split('T')[0] : null,
  })),
  on(setOfflineOrders, (state, { payload }) => ({
    ...state,
    orders: payload,
    orderCount: getOfflineOrdersCount(payload),
  })),
  on(setOfflineDataDownloading, (state, { payload }) => ({
    ...state,
    downloading: payload,
  })),
  on(setOfflineOrdersSubmitting, (state, { payload }) => ({
    ...state,
    submitting: payload,
  })),
  on(setOfflineError, (state, { payload }) => ({
    ...state,
    hasError: payload,
  })),
  on(setOfflineModeValue, (state, { payload }) => ({
    ...state,
    value: payload,
  })),
);

export function offlineModeReducer(state: OfflineModeState, action: Action) {
  return _offlineModeReducer(state, action);
}
