@if (hasVariants && !hasSelectedVariants) {
  @for (order of data.orders; track order) {
    @if (order.all_variants?.length > 0) {
      <win-order-variant
        class="variants"
        [order]="order"
        (variantsSelected)="variantsSelected(order, $event)"
      ></win-order-variant>
    }
  }
} @else {
  @for (order of data.orders; track order) {
    <div>
      <win-order-description
        #orderDescription
        class="order"
        [autocomplete]="autocomplete$ | async"
        [consumer]="data.consumer"
        [isAgent]="data.isAgent"
        [isManager]="data.isManager"
        [isOffline]="data.isOffline"
        [featureConsistency]="data.featureConsistency"
        [featureTexture]="data.featureTexture"
        [order]="order"
        [quantityFieldIncrement]="data.quantityFieldIncrement"
        [quantityFieldInput]="data.quantityFieldInput"
        [minQuantity]="data.minQuantity"
        [showDescription]="data.showDescription"
        [showOnlyPortionSize]="data.showOnlyPortionSize"
        [showOnlyQuantity]="data.showOnlyQuantity"
        (descriptionChange)="descriptionChanged(order, $event)"
        (fetchProductSheet)="data.fetchProductSheet($event)"
        (searchAutocomplete)="data.searchAutocomplete($event)"
      ></win-order-description>
    </div>
  }
}
<win-save-stripe class="add-order mat-elevation-z2">
  <button class="mat-button fill-parent" mat-button (click)="saveData()">
    {{
      'orders.descriptions.' +
        (hasVariants && !hasSelectedVariants ? 'select-variants' : 'add-button')
        | transloco
    }}
  </button>
</win-save-stripe>
