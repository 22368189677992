import { Component, Input, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ManagableAccess } from 'src/app/shared/models/consumers';
import { ManagableOrder, Order } from 'src/app/shared/models/orders';

import { selectGlobalLanguage } from '../../../redux/global/global.selectors';
import { TranslocoPipe } from '@jsverse/transloco';
import { AsyncPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'win-order-date',
  templateUrl: './order-date.component.html',
  styleUrls: ['./order-date.component.scss'],
  standalone: true,
  imports: [AsyncPipe, DatePipe, TranslocoPipe],
})
export class OrderDateComponent {
  private readonly store = inject(Store);

  @Input() item: ManagableOrder | Order | ManagableAccess;
  globalLanguage$ = this.store.pipe(select(selectGlobalLanguage));
}
