@if (
  (isDailyMenu ? order.section_level2 : order.section_level1) &&
  (!orderPrev ||
    (isDailyMenu
      ? orderPrev.section_level2 !== order.section_level2
      : orderPrev.section_level1 !== order.section_level1))
) {
  <div class="item title" [class.subtle]="subtleUI">
    {{
      isDailyMenu
        ? order.section_level2
          ? order.section_level2
          : '-'
        : order.section_level1
    }}
  </div>
}
<div
  class="item content-wrapper wrapper"
  [class.bordered]="
    orderPrev &&
    order.section_level3 &&
    order.section_level3 !== orderPrev.section_level3
  "
  [class.subtle]="subtleUI"
>
  <div class="content">
    <div class="quantity">
      {{
        isDailyMenu
          ? order.section_level3
            ? order.section_level3
            : '-'
          : order.section_level2
      }}
    </div>
    <div>
      @if (order?.quantity > 1) {
        <span class="quantity">{{ order?.quantity }} &times;</span>
      }
      {{ order?.item ? order?.item : '-' }}
    </div>
    <div>
      @if (order?.variants?.length) {
        :
      }
      @for (variant of order?.variants; track variant; let isLast = $last) {
        <span class="variant">
          {{ variant.item }}
          @if (!isLast) {
            ,
          }
        </span>
      }
      @if (order?.portion_size && order?.portion_size !== 1) {
        <span class="portion">
          ({{
            'orders.descriptions.portions.' + order?.portion_size * 10
              | transloco
          }})</span
        >
      }
      @if (order?.texture) {
        <span class="consistency">
          ({{
            'orders.descriptions.texture.' + order?.texture | transloco
          }})</span
        >
      }
      @if (order?.consistency) {
        <span class="consistency">
          ({{
            'orders.descriptions.consistency.' + order?.consistency | transloco
          }})</span
        >
      }
      @if (order?.description) {
        <span class="description">
          {{ order?.description }}
        </span>
      }
    </div>
  </div>
  @if (showRepeat) {
    <win-repeat-order
      class="repeat-order"
      [checkin]="checkin"
      [checkout]="checkout"
      [disabledRepeatOptions]="disabledRepeatOptions"
      [isOffline]="isOffline"
      [lang]="lang"
      [repeatOrder]="repeatOrder"
      (selectionChanged)="
        repeatChanged.emit({ data: $event, url: repeatOrder?.url })
      "
    ></win-repeat-order>
  }
</div>
