import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { Course } from 'src/app/shared/models/menus';
import { CoursesSliderComponent } from './courses-slider/courses-slider.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'win-build-new-skills',
  templateUrl: './build-new-skills.component.html',
  styleUrls: ['./build-new-skills.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    CoursesSliderComponent,
    MatButtonModule,
    RouterLink,
    TranslocoPipe,
  ],
})
export class BuildNewSkillsComponent {
  @Input() courses: Course[];
  @Input() isManager: boolean;
  @Input() lang: string;
  @Input() loading: boolean;

  @Output() addNewCourse = new EventEmitter<void>();
  @Output() deleteCourse = new EventEmitter<number>();
  @Output() editCourse = new EventEmitter<Course>();
}
