@if ((loadingState$ | async)) {
<mat-spinner color="primary"></mat-spinner>
} @else if ((confirmationResult$ | async)) {
<div class="wrapper">
  <h1 class="mat-h1">{{'confirm-email.success.title' | transloco}}</h1>
  <div data-cy="success-image" class="success-image">
    <img src="img/confirmed.gif" alt="Confirmed" />
  </div>
  <p>
    {{'confirm-email.success.description' | transloco }}
    <a routerLink="/menus">{{'confirm-email.success.click' | transloco }}</a>
  </p>
</div>
} @else {
<div data-cy="error-message" class="wrapper">
  <h1>{{ "confirm-email.failure.title" | transloco }}</h1>
  <p class="mat-body">
    {{ "confirm-email.failure.description_1" | transloco }} <br />{{
    "confirm-email.failure.description_2" | transloco }}
  </p>
  <hr />
  <p>
    {{ "confirm-email.failure.not_working" | transloco }}
    <a data-cy="support-link" [href]="'mailto:' + supportEmail"
      >{{ "confirm-email.failure.link" | transloco }}</a
    >
    {{ "confirm-email.failure.link_description" | transloco }}
  </p>
</div>
}
