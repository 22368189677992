<h2 mat-dialog-title>
  {{ 'app.offline.errors.title' | transloco }}
</h2>

<div mat-dialog-content>
  <div class="icon-container">
    <mat-icon>dangerous</mat-icon>
  </div>
  <p>{{ 'app.offline.errors.description' | transloco }}</p>
</div>

<div mat-dialog-actions class="actions">
  <button mat-button (click)="close()">
    {{ 'app.offline.errors.continue-offline' | transloco }}
  </button>
  <button mat-fab extended color="primary" (click)="resubmitOrders()">
    {{ 'app.offline.errors.resubmit' | transloco }}
  </button>
</div>
