<button
  mat-button
  class="wrapper mat-elevation-z2"
  data-cy="menu"
  [class.locked]="isLocked"
  [class.can-order]="menu().can_order"
  [class.has-date]="menu().date !== null"
  [matTooltip]="isLocked ? ('menus.tooltip' | transloco) : ''"
  matTooltipPosition="above"
  matTooltipClass="win-tooltip"
  (click)="validateClick($event)"
>
  <div class="name mat-subtitle-1">
    {{
      menu().show_date && !menu().show_name
        ? (menu().date | date: 'EEEE' : undefined : lang())
        : menu().name
    }}&nbsp;
    @if (menu().can_order) {
      <mat-icon class="action-icon">keyboard_arrow_right</mat-icon>
    } @else if (isLocked && !menu().has_orders) {
      <mat-icon class="action-icon">lock</mat-icon>
    }
  </div>
  @if (menu().show_date) {
    <div class="date mat-subtitle-2">
      @if (menu().show_name && menu().show_date) {
        {{ menu().date | date: 'EEEE' : undefined : lang() }},
      }
      {{ menu().date | yearlessDate: 'longDate' : undefined : lang() }}
    </div>
  }
  <div class="order-info">
    @if (menu().has_orders || menu().list_orders.length) {
      <span class="mat-body submitted">
        @if (menu().list_orders.length) {
          {{ 'menus.menu.order-for' | transloco }}:
          @for (orderDate of menu().list_orders; track orderDate) {
            @if (orderDate === today) {
              <span class="submitted-date">{{
                'menus.menu.today' | transloco
              }}</span>
            } @else if (orderDate === tomorrow) {
              <span class="submitted-date">{{
                'menus.menu.tomorrow' | transloco
              }}</span>
            } @else {
              <span class="submitted-date">{{
                orderDate | date: 'EEEE' : undefined : lang()
              }}</span>
            }
          }
        } @else {
          <span>
            {{ 'menus.menu.order-submitted' | transloco }}
          </span>
        }
      </span>
    } @else if (menu().can_order) {
      <span class="mat-caption">{{ 'menus.menu.no-order' | transloco }}</span>
    }
  </div>
  @if (menu().checkout_day || menu().birthday_day) {
    <div class="badge" [class.birthday]="menu().birthday_day">
      @if (menu().checkout_day) {
        <span>
          {{ 'menus.menu.last-day' | transloco: menu().showBadge }}
          @if (menu().birthday_day) {
            <span>&nbsp;</span>
          }
        </span>
      }
      @if (menu().birthday_day) {
        <mat-icon>cake</mat-icon>
      }
    </div>
  }
  @if (menu().date === null) {
    @if (menu().can_order) {
      <mat-icon class="action-icon">keyboard_arrow_right</mat-icon>
    } @else if (isLocked && !menu().has_orders) {
      <mat-icon class="action-icon">lock</mat-icon>
    }
  }
  @if (menu().can_order && menu().showBadge && menu().showBadge.time === null) {
    <div class="mat-caption badge-banner past">
      <mat-icon>hourglass_bottom</mat-icon>
      &nbsp;
      <span>
        {{ 'menus.menu.deadline-passed' | transloco }}
      </span>
    </div>
  } @else if (
    menu().can_order && menu().showBadge && menu().showBadge.today === true
  ) {
    <div class="mat-caption badge-banner today">
      <mat-icon>more_time</mat-icon>
      &nbsp;
      <span>
        {{ 'menus.menu.deadline-passed-today' | transloco: menu().showBadge }}
      </span>
    </div>
  } @else if (menu().can_order && menu().showBadge) {
    <div class="mat-caption badge-banner">
      <mat-icon>notifications_none</mat-icon>
      &nbsp;
      <span>
        {{ 'menus.menu.until-today' | transloco: menu().showBadge }}
      </span>
    </div>
  }
</button>
