import { Observable } from 'rxjs';
import { LoginForm, VerifySuccess } from 'src/app/shared/models/authentication';

import {
  InvitationResult,
  Organisation,
  RoomConsumer,
} from '../../models/user';
import { LoginSuccess, RegistrationForm } from '../../models/authentication';

export const TOKEN_KEY = 'mt_orders_access_token';
export const REFRESH_TOKEN_KEY = 'mt_orders_refresh_token';

export interface AuthenticationService {
  login: (data: LoginForm) => Observable<LoginSuccess>;

  refreshToken: (
    refreshToken: string,
  ) => Observable<{ access: string; refresh: string }>;

  logoutFromRoom: (uid: number) => Observable<any>;

  loginToRoom: (
    uid: number,
    data: {
      rfid?: string;
      room?: string;
      name?: string;
      checkout?: string;
      custom_data?: any;
    },
  ) => Observable<any>;

  register: (data: RegistrationForm) => Observable<LoginSuccess>;

  consumerTerminalLogin: (
    uid: number,
    data: {
      uuid: string;
    },
  ) => Observable<any>;

  retrieveUuid: (
    consumerName: any,
    key: string,
    auth_room: string,
  ) => Observable<{ uuid: string }>;

  consumerNameTerminalLogin: (
    uid: number,
    data: {
      room: string;
      name?: string;
      first_name?: string;
      last_name?: string;
    },
  ) => Observable<any>;

  validateKey: (key: string) => Observable<Organisation>;

  validateRoom: (
    uid: number,
    data: {
      room?: string;
    },
  ) => Observable<{ message: string } | RoomConsumer[]>;

  checkInvitation: (key: string) => Observable<InvitationResult>;

  resetPassword: (data: { email: string }) => Observable<any>;

  saveToken: (data: LoginSuccess) => void;

  saveAccessToken: (access: string, refresh?: string) => void;

  removeToken: () => void;

  getToken: () => string | undefined;

  getRefreshToken: () => string | undefined;

  checkEmail: (email: string) => Observable<any>;

  confirmEmail: (key: string) => Observable<VerifySuccess>;

  confirmPasswordReset: (data: {
    new_password1: string;
    new_password2: string;
    uid: string;
    token: string;
  }) => Observable<LoginSuccess>;

  resendEmailConfirmation: () => Observable<any>;

  verifyPassword: (userUrl: string, password: string) => Observable<any>;
}
