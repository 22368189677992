import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta } from '@angular/platform-browser';
import { getBrowserLang, TranslocoService } from '@jsverse/transloco';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { de, enUS, es, fr, it } from 'date-fns/locale';
import { EMPTY } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { FormErrors } from 'src/app/shared/models/globals';

import {
  extractFormErrors,
  filterLang,
  flattenObject,
} from '../../shared/utils.functions';
import { restoreUserLocally } from '../user/user.actions';
import {
  addFormError,
  fetchAutocompleteSearch,
  handleHttpError,
  setAutocompleteSearch,
  setFormErrors,
  setGlobalLanguage,
  setGlobalMetaTags,
  showSnackbarMessage,
} from './global.actions';
import { DOCUMENT } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { UserService } from 'src/app/shared/services/user/user.service';

const NON_AUTH_FORM_ERRORS = [`message`, `token`, `error`];

@Injectable()
export class GlobalEffects {
  private readonly _document = inject(DOCUMENT);
  private readonly actions$ = inject(Actions);
  private readonly dateAdapter = inject<DateAdapter<Date>>(DateAdapter);
  private readonly matIconRegistry = inject(MatIconRegistry);
  private readonly meta = inject(Meta);
  private readonly snackBar = inject(MatSnackBar);
  private readonly translationService = inject(TranslocoService);
  private readonly userService = inject(UserService);

  afterEffectsInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      mergeMap(() => {
        this.matIconRegistry.setDefaultFontSetClass(
          'material-symbols-outlined',
        );
        const lang = filterLang(getBrowserLang());
        return [setGlobalLanguage({ payload: lang }), restoreUserLocally()];
      }),
      catchError(() => EMPTY),
    ),
  );

  setGlobalLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setGlobalLanguage),
      tap(({ payload }) => {
        this.translationService.setActiveLang(payload);
        this.dateAdapter.setLocale(this.localeMap[payload]);
      }),
      mergeMap(({ payload }) => [setGlobalMetaTags({ payload })]),
    ),
  );

  setGlobalMetaTags$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setGlobalMetaTags),
        tap(({ payload }) => {
          // Set the language attribute on the <html> tag
          this._document.querySelector('html').setAttribute('lang', payload);
        }),
        switchMap(({ payload }) =>
          this.translationService.selectTranslateObject<Record<string, string>>(
            'app.meta',
            undefined,
            payload,
          ),
        ),
        mergeMap((metaTags) => {
          if (!metaTags || typeof metaTags === 'string') return EMPTY;
          // Set the description meta tag
          Object.entries(metaTags).forEach(([key, value]) => {
            this.meta.updateTag({
              name: key,
              content: value,
            });
          });
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  showSnackbarMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showSnackbarMessage),
        switchMap(
          ({ errorCode, message, snackClass, button, duration, action }) => {
            if (message && message !== `<`) {
              const snackBarRef = this.snackBar.open(message, button, {
                duration: duration ?? 3000,
                panelClass: snackClass,
              });
              if (action) {
                snackBarRef.onAction().subscribe(() => action());
              }
            }
            if ([500, 502].includes(errorCode)) {
              this.snackBar.open(
                this.translationService.translate('shared.server-error'),
              );
            }
            return EMPTY;
          },
        ),
        catchError(() => EMPTY),
      ),
    { dispatch: false },
  );

  handleHttpError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handleHttpError),
      switchMap(({ error, formId, forceSnackbar }) => {
        if (!(error instanceof HttpErrorResponse)) throw error;
        if (!error?.error) return EMPTY;
        // handle form/field error
        let errors: FormErrors = extractFormErrors(error.error);
        if (error.status === 400 && errors && !forceSnackbar) {
          if (NON_AUTH_FORM_ERRORS.some((key) => key in errors)) {
            errors = Object.entries(errors).reduce((acc, [, value]) => {
              acc['non_field_errors'] = value as string[];
              return acc;
            }, {});
          }
          const actionsToDispatch = [];
          if (Object.keys(errors).length > 1 || !errors?.non_field_errors) {
            actionsToDispatch.push(setFormErrors({ payload: errors }));
          }
          if (
            errors.non_field_errors &&
            typeof errors.non_field_errors === 'string'
          ) {
            actionsToDispatch.push(
              addFormError({
                error: { form_id: formId, message: errors.non_field_errors },
              }),
            );
          }
          return actionsToDispatch as [];
        } else {
          const flatObj = flattenObject(error.error);
          const firstErrorMessage =
            (error.error as { message: string })?.message ||
            (Object.values(flatObj)[0] as string);
          if (firstErrorMessage) {
            return [showSnackbarMessage({ message: firstErrorMessage })];
          }
          return EMPTY;
        }
      }),
      // catchError(() => EMPTY)
    ),
  );

  fetchAutocompleteCustomData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAutocompleteSearch),
      switchMap(({ field, value, model }) => {
        let params = new HttpParams();
        params = params.append('field', field);
        params = params.append('value', value);
        params = params.append('model', model);
        return this.userService.fetchSearch(params).pipe(
          mergeMap(({ results }) => [setAutocompleteSearch({ results })]),
          catchError((error: unknown) => [handleHttpError({ error })]),
        );
      }),
    ),
  );

  localeMap = {
    en: enUS,
    de: de,
    fr: fr,
    es: es,
    it: it,
  };
}
