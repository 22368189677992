import { createAction, props } from '@ngrx/store';
import { Results } from 'src/app/shared/models/generics';
import { Course } from 'src/app/shared/models/menus';

export const createCourse = createAction(
  `[Build new skills] Create new course`,
  props<{ payload: { payload: Partial<Course>; redirect: boolean } }>(),
);

export const addCreatedCourse = createAction(
  `[Build new skills] Add created course locally`,
  props<{ payload: Course }>(),
);

export const fetchCourses = createAction(`[Build new skills] Fetch courses`);

export const setCourses = createAction(
  `[Build new skills] Set fetched courses`,
  props<{ items: Results<Course> }>(),
);

export const setCoursesDataLoading = createAction(
  `[Build new skills] Set Table data loading locally`,
  props<{ loading: boolean }>(),
);

export const deleteCourse = createAction(
  `[Build new skills] Delete course`,
  props<{ courseId: number }>(),
);

export const removeDeletedCourse = createAction(
  `[Build new skills] Remove deleted course locally`,
  props<{ courseId: number }>(),
);

export const setSelectedCourse = createAction(
  `[Build new skills] Set selected course locally`,
  props<{ course: Course }>(),
);

export const updateCourse = createAction(
  `[Build new skills] Update course`,
  props<{
    payload: { payload: Partial<Course>; courseId: number };
  }>(),
);

export const setUpdatedCourse = createAction(
  `[Build new skills] Set updated course locally`,
  props<{ course: Partial<Course>; courseId: number }>(),
);
