<div class="wrapper" [class.hide]="hide">
  <div class="title">
    <span class="title-name">
      @if (!showDietaryCalendarLabel) {
        @if (orderCompleted) {
          {{ 'app.consumer-info.order-completed-for' | transloco }}
        } @else {
          {{ 'app.consumer-info.order-for' | transloco }}
        }
      }
      @if (showDietaryCalendarLabel) {
        {{ 'schedule-diets.dietary-calendar-for' | transloco }}
      }
      <span class="name">
        {{ consumer?.name }}
        @if (consumer?.room) {
          <span class="name-room"
            >({{ 'app.consumer-info.room' | transloco }}
            {{ consumer.room }})</span
          >
        }
      </span>
    </span>
  </div>
  <div
    [matTooltip]="'app.offline.not-available' | transloco"
    [matTooltipDisabled]="isOffline === false"
    matTooltipClass="win-tooltip"
  >
    @if (showApplyOrder) {
      <button
        (click)="triggerDefaultOrder()"
        mat-button
        class="apply-default-order-button"
        [disabled]="isOffline"
      >
        {{ 'app.consumer-info.apply-order' | transloco }}
      </button>
    }
  </div>
  @if (orders?.length || hasItemsSelected) {
    <button mat-button class="unselect-all-orders" (click)="unSelectAll.emit()">
      {{ 'app.consumer-info.unselect-all' | transloco }}
    </button>
  }
  @if (filteredDiets?.length) {
    <div
      class="order-diets"
      [matTooltip]="'app.offline.not-available' | transloco"
      [matTooltipDisabled]="isOffline === false"
      matTooltipClass="win-tooltip"
    >
      <span class="field-label"
        >{{ 'app.consumer-info.menu-shown' | transloco }}:</span
      >
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{
          'manage-accesses.table.filters.diet' | transloco
        }}</mat-label>
        <mat-select [formControl]="consumerDiet" name="diets" multiple>
          @for (diet of filteredDiets; track diet) {
            <mat-option [value]="diet?.id">{{ diet?.name }}</mat-option>
          }
        </mat-select>
        @if (consumerDiet.value?.length > 0) {
          <button
            mat-icon-button
            matIconSuffix
            (click)="clearField($event, 'diets')"
          >
            <mat-icon>clear</mat-icon>
          </button>
        }
      </mat-form-field>
      <button
        [disabled]="applyDietDisabled || isOffline"
        (click)="applyOrderDiets()"
        mat-button
        class="apply-default-order"
      >
        {{ 'app.consumer-info.apply' | transloco }}
      </button>
    </div>
  }
  @if (showSearchMenu) {
    <div class="search-menu">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{
          'app.consumer-info.search-in-menu' | transloco
        }}</mat-label>
        <input
          matInput
          type="text"
          [formControl]="searchMenu"
          name="searchMenu"
        />
        @if (searchMenu?.value) {
          <button
            mat-icon-button
            matIconSuffix
            (click)="clearMenuSearchField($event, 'searchMenu')"
          >
            <mat-icon>clear</mat-icon>
          </button>
        } @else {
          <button mat-icon-button matIconSuffix>
            <mat-icon>search</mat-icon>
          </button>
        }
      </mat-form-field>
    </div>
  }
  @if (nonRegularTranslations?.length) {
    <div class="set-translation">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>
          {{ 'app.consumer-info.translation' | transloco }}</mat-label
        >
        <mat-select [formControl]="translation" name="translations">
          @for (translation of nonRegularTranslations; track translation) {
            <mat-option [value]="translation">{{
              'shared.other-languages.' + translation | transloco
            }}</mat-option>
          }
        </mat-select>
        @if (translation.value) {
          <button
            mat-icon-button
            matIconSuffix
            (click)="clearTranslation($event)"
          >
            <mat-icon>clear</mat-icon>
          </button>
        }
      </mat-form-field>
    </div>
  }
  @if (
    showDetail &&
    ((consumer?.custom_data && (consumer?.custom_data | keyvalue).length) ||
      consumer?.diets?.length ||
      consumer?.type ||
      consumerObject?.allergies?.length ||
      consumerObject?.intolerances?.length ||
      consumerObject?.texture ||
      consumerObject?.consistency ||
      (consumer?.portion_size && consumer?.portion_size !== 1))
  ) {
    <a class="toggle-link mat-caption" (click)="toggleVisibility()">{{
      (hide ? 'app.consumer-info.show' : 'app.consumer-info.hide') | transloco
    }}</a>
  }
  @if (
    showDetail &&
    ((consumer?.custom_data && (consumer?.custom_data | keyvalue).length) ||
      consumer?.diets?.length ||
      consumer?.type ||
      consumerObject?.allergies?.length ||
      consumerObject?.intolerances?.length ||
      consumerObject?.texture ||
      consumerObject?.consistency ||
      (consumer?.portion_size && consumer?.portion_size !== 1))
  ) {
    <win-consumer-data
      [consumer]="consumer"
      [dietsScheduled]="dietsScheduled"
      [lang]="lang"
      [class.hidden]="hide"
      class="data"
    ></win-consumer-data>
  }
</div>
