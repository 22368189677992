import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { DietsService } from './diets.service';
import { DietParams } from 'src/app/shared/models/diets';

@Injectable({ providedIn: 'root' })
export class OfflineDietsService implements DietsService {
  fetchDiets(params: DietParams) {
    return of([]);
  }
}
