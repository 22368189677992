import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Order, OrderVariant } from 'src/app/shared/models/orders';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'win-order-variant',
  templateUrl: './order-variant.component.html',
  styleUrls: ['./order-variant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DecimalPipe],
})
export class OrderVariantComponent implements OnChanges {
  @Input() order: Order;
  @Output() variantsSelected = new EventEmitter<OrderVariant[]>();

  selectedVariants: OrderVariant[] = [];
  selectedVariantIds: number[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if ('order' in changes && changes.order.firstChange) {
      this.selectedVariants.push(...this.order.variants);
      this.selectedVariantIds.push(
        ...this.order.variants.map((val) => val.variant_id),
      );
    }
  }

  handleSelectedVariant(element: HTMLDivElement) {
    const variantId = parseInt(element.dataset?.variant);
    element.classList.toggle('selected');
    if (this.selectedVariantIds.includes(variantId)) {
      this.selectedVariants = this.selectedVariants.filter(
        (variant) => variant.variant_id !== variantId,
      );
      this.selectedVariantIds = this.selectedVariantIds.filter(
        (val) => val !== variantId,
      );
    } else {
      const newVariant = {
        variant_id: parseInt(element.dataset?.variant),
        item: element.dataset?.item,
        item_baselang: element.dataset?.itemBase,
        price: element.dataset?.price ? +element.dataset?.price : null,
      } as OrderVariant;
      this.selectedVariants.push(newVariant);
      this.selectedVariantIds.push(newVariant.variant_id);
    }
    this.variantsSelected.emit(this.selectedVariants);
  }
}
