import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { API_CONFIG } from 'src/app/app.config';
import {
  Report,
  ReportFormat,
  UnauthConsumer,
  User,
  UserPasswordChangedData,
} from 'src/app/shared/models/user';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly http = inject(HttpClient);
  private readonly utilsService = inject(UtilsService);

  private readonly getUserEP = `${API_CONFIG.orderTakingApi}/users/me/`;
  private readonly changePasswordEP = `${API_CONFIG.orderTakingApi}/rest-auth/password/change/`;
  private readonly consumerOrganisationEP = `${API_CONFIG.orderTakingApi}/consumers/me/`;
  private readonly reportsEP = `${API_CONFIG.orderTakingApi}/reports/`;
  private readonly searchEP = `${API_CONFIG.orderTakingApi}/search/`;

  getUser = () => this.utilsService.get<User>(this.getUserEP);

  getConsumerOrganisation = () =>
    this.utilsService.get<UnauthConsumer>(this.consumerOrganisationEP);

  changePassword = (data: UserPasswordChangedData) =>
    this.utilsService.post<UserPasswordChangedData, { detail: string }>(
      this.changePasswordEP,
      data,
    );

  getReports = () => this.utilsService.get<Report[]>(this.reportsEP);

  downloadReport = (lang: string, report: string, response?: ReportFormat) => {
    return this.http.post(
      this.reportsEP,
      { report, ...(response ? { response } : {}), lang },
      {
        observe: 'response',
        responseType: `blob` as `json`,
      },
    );
  };

  fetchSearch = (params: HttpParams) => {
    return this.http.get<{ count: number; results: string[] }>(this.searchEP, {
      params,
    });
  };
}
