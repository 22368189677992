import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { Course } from 'src/app/shared/models/menus';

@Component({
  selector: 'win-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    MatMenuModule,
    TranslocoPipe,
  ],
})
export class CourseComponent {
  @Input() course: Course;
  @Input() isManager: boolean;
  @Input() lang: string;

  @Output() deleteCourse = new EventEmitter<number>();
  @Output() editCourse = new EventEmitter<Course>();
}
