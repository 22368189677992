import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { API_CONFIG } from '../../../app.config';
import { Results } from '../../models/generics';
import { Course, FetchMenusParams, Menu } from '../../models/menus';
import { MenusService } from './menus.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Injectable({ providedIn: 'root' })
export class OnlineMenusService implements MenusService {
  private readonly utilsService = inject(UtilsService);

  private readonly menusEP = `${API_CONFIG.orderTakingApi}/menus/`;
  private readonly coursesEP = `${API_CONFIG.orderTakingApi}/courses/`;

  fetchMenus = (
    params: FetchMenusParams | { own: `true` } = { own: `true` },
  ): Observable<Results<Menu>> =>
    this.utilsService.get<Results<Menu>>(this.menusEP, params);

  fetchMenu = (params: {
    menu: string;
    date: string;
  }): Observable<Results<Menu>> =>
    this.utilsService.get<Results<Menu>>(`${this.menusEP}`, params);

  createCourses = (payload: Partial<Course>) =>
    this.utilsService.post<Partial<Course>, Course>(this.coursesEP, payload);

  fetchCourses = () =>
    this.utilsService.get<Results<Course>>(`${this.coursesEP}`);

  deleteCourses = (courseId: number) =>
    this.utilsService.delete(`${this.coursesEP}${courseId}/`);

  updateCourses = (course: Partial<Partial<Course>>, courseId: number) =>
    this.utilsService.patch<Partial<Partial<Course>>, Course>(
      `${this.coursesEP}${courseId}/`,
      course,
    );
}
