import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OfflineModeState,
  offlineModeFeatureKey,
} from './offline-mode.reducer';

export const selectState = createFeatureSelector<OfflineModeState>(
  offlineModeFeatureKey,
);

export const selectOfflineModeValue = createSelector(
  selectState,
  (state) => state.value,
);

export const selectOfflineModeConsumer = createSelector(
  selectState,
  (state) => state.consumer,
);

export const selectOfflineModeDate = createSelector(
  selectState,
  (state) => state.date,
);

export const selectOfflineOrders = createSelector(
  selectState,
  (state) => state.orders,
);

export const selectOfflineOrderCount = createSelector(
  selectState,
  (state) => state.orderCount,
);

export const selectOfflineDataDownloading = createSelector(
  selectState,
  (state) => state.downloading,
);

export const selectOfflineOrdersSubmitting = createSelector(
  selectState,
  (state) => state.submitting,
);

export const selectOfflineError = createSelector(
  selectState,
  (state) => state.hasError,
);
