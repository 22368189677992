import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  SimpleChanges,
  inject,
  input,
  output,
} from '@angular/core';
import { Menu } from 'src/app/shared/models/menus';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { DatePipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { YearlessDatePipe } from 'src/app/shared/pipes/yearless-date.pipe';
import { DateAdapter } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';

@Component({
  selector: 'win-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    DatePipe,
    TranslocoPipe,
    YearlessDatePipe,
  ],
  providers: [DatePipe],
})
export class MenuComponent implements OnChanges {
  private readonly dateAdapter = inject<DateAdapter<Date>>(DateAdapter);

  lang = input.required<InterfaceLanguage>();
  menu = input.required<Menu>();

  openMenu = output<void>();

  isLocked = false;
  today: string;
  tomorrow: string;

  constructor() {
    this.today = this.dateAdapter.format(new Date(), 'yyyy-MM-dd');
    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    this.tomorrow = this.dateAdapter.format(tomorrow, 'yyyy-MM-dd');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('menu' in changes && changes.menu) {
      this.isLocked = !this.menu().can_order && !this.menu().has_orders;
    }
  }

  validateClick(event: MouseEvent): void {
    if (this.isLocked) {
      event.stopPropagation();
    } else {
      this.openMenu.emit();
    }
  }
}
