import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { isEqual } from 'lodash-es';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SelectFieldModel } from 'src/app/shared/models/misc';
import {
  AnyAccessType,
  Consumer,
  isManagableOrderOrConsumer,
} from '../../models/consumers';
import { ManagableOrder, Order } from '../../models/orders';
import { TranslocoPipe } from '@jsverse/transloco';
import { ConsumerDataComponent } from './consumer-data/consumer-data.component';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { KeyValuePipe } from '@angular/common';
import { InterfaceLanguage } from '../../constants/languages';

@Component({
  selector: 'win-consumer-info',
  templateUrl: './consumer-info.component.html',
  styleUrls: ['./consumer-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatTooltipModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatIconModule,
    MatInputModule,
    ConsumerDataComponent,
    KeyValuePipe,
    TranslocoPipe,
  ],
})
export class ConsumerInfoComponent implements OnChanges, OnInit {
  private readonly destroyRef = inject(DestroyRef);

  @Input() consumer: AnyAccessType | ManagableOrder;
  @Input() currentMenuDiets: string[];
  @Input() dietsScheduled: string[];
  @Input() hasItemsSelected: boolean;
  @Input() hide = false;
  @Input() isOffline: boolean;
  @Input() nonRegularTranslations: string[];
  @Input() orders: Order[];
  @Input() showApplyOrder = false;
  @Input() showDetail = false;
  @Input() orderCompleted = false;
  @Input() showDietaryCalendarLabel = false;
  @Input() showDiets = false;
  @Input() diets: SelectFieldModel[];
  @Input() lang: InterfaceLanguage;
  @Input() showSearchMenu = false;

  @Output() applyDefaultOrder = new EventEmitter<{
    consumer: AnyAccessType | ManagableOrder;
    selectedDiets: string[];
  }>();

  @Output() applyDiets = new EventEmitter<string[]>();
  @Output() applySearchFilter = new EventEmitter<string>();
  @Output() applyTranslation = new EventEmitter<string>();
  @Output() unSelectAll = new EventEmitter();

  applyDietDisabled = true;
  consumerDiet = new FormControl<string[]>([]);
  translation = new FormControl('');
  currentDiets: string[];
  searchMenu = new FormControl('');
  filteredDiets: SelectFieldModel[];
  consumerObject: Consumer | ManagableOrder;

  ngOnChanges(changes: SimpleChanges) {
    if (
      ('diets' in changes ||
        'consumer' in changes ||
        'currentMenuDiets' in changes) &&
      this.diets &&
      this.consumer &&
      this.currentMenuDiets
    ) {
      this.filterDiets(this.consumer.location);
    }
    if ('isOffline' in changes) {
      if (this.isOffline) {
        this.consumerDiet.disable();
      } else {
        this.consumerDiet.enable();
      }
    }
    if ('consumer' in changes && this.consumer) {
      this.consumerObject = isManagableOrderOrConsumer(this.consumer)
        ? this.consumer
        : null;
    }
  }

  ngOnInit() {
    this.consumerDiet.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(400),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value: string[]) => {
        const selectedDiets = [...value].sort((a, b) => a.localeCompare(b));
        this.applyDietDisabled = isEqual(selectedDiets, this.currentDiets);
        this.consumerDiet.updateValueAndValidity();
      });
    this.translation.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe((value: string) => {
        this.applyTranslation.emit(value);
      });
    this.searchMenu.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(400),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value: string) => {
        this.applySearchFilter.emit(value);
      });
  }

  applyOrderDiets() {
    const selectedDiets = [...this.consumerDiet.value].sort((a, b) =>
      a.localeCompare(b),
    );
    this.applyDiets.emit(selectedDiets);
    this.currentDiets = selectedDiets;
  }

  clearField(event: MouseEvent, field: string): void {
    event.stopPropagation();
    event.preventDefault();
    this.consumerDiet.setValue([]);
  }

  clearTranslation(event) {
    event.stopPropagation();
    event.preventDefault();
    this.translation.setValue('');
  }

  clearMenuSearchField(event: MouseEvent, field: string): void {
    event.stopPropagation();
    event.preventDefault();
    this.searchMenu.setValue('');
  }

  filterDiets(location: number | null): void {
    this.filteredDiets = this.diets.filter((t) => t.location === location);
    this.consumerDiet.setValue(this.currentMenuDiets);
    this.currentDiets = [...this.currentMenuDiets].sort((a, b) =>
      a.localeCompare(b),
    );
  }

  toggleVisibility(): void {
    this.hide = !this.hide;
  }

  triggerDefaultOrder(): void {
    this.applyDefaultOrder.emit({
      consumer: this.consumer as any,
      selectedDiets: this.consumerDiet.value,
    });
  }
}
