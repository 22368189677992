import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CoursesState, adapter, coursesFeatureKey } from './courses.reducer';

export const selectState =
  createFeatureSelector<CoursesState>(coursesFeatureKey);

const { selectAll } = adapter.getSelectors();

export const selectCourses = createSelector(selectState, (state) =>
  selectAll(state?.items),
);

export const selectIsCoursesLoading = createSelector(
  selectState,
  (state) => state?.loading,
);

export const selectCurrentCourse = createSelector(
  selectState,
  (state) => state?.selectedCourse,
);
