export enum WebsocketAction {
  CREATE = 'create',
  DELETE = 'delete',
  RESET = 'reset',
  RESET_DELETE = 'reset_delete',
}

export interface WebsocketMessage {
  action: WebsocketAction;
  data: any;
  service: string;
  date: string;
}
