import { createAction, props } from '@ngrx/store';

import { Diet } from '../../shared/models/diets';

export const fetchDiets = createAction(
  `[Orders / managing container] Fetch diets`,
);

export const setDiets = createAction(
  `[Orders / managing container] Set diets`,
  props<{ payload: Diet[] }>(),
);
