<win-confirm-orders
  [consumer]="consumer$ | async"
  [disabledRepeatOptions]="selectDisabledRepeatOptions$ | async"
  [editedItem]="managableItem$ | async"
  [goBackPage]="backQueryParam$ | async"
  [isManager]="isManager$ | async"
  [isOffline]="isOffline$ | async"
  [isTerminal]="isTerminal$ | async"
  [lang]="globalLanguage$ | async"
  [menu]="menu$ | async"
  [orders]="orders$ | async"
  [ordersFetched]="ordersFetched$ | async"
  [repeatOrders]="repeatOrders$ | async"
  [selectedDate]="selectedDate$ | async"
  [showBackLink]="showBackLink$ | async"
  [showConsumerInfo]="selectShowConsumerInfo$ | async"
  [showConsumerInfoDetail]="selectShowConsumerInfoDetail$ | async"
></win-confirm-orders>
