import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { CustomData, RoomConsumer } from 'src/app/shared/models/user';

import { AuthenticationService } from './authentication.service';
import { AbstractAuthenticationService } from './abstract.authentication.service';

export const TOKEN_KEY = 'mt_orders_access_token';
export const REFRESH_TOKEN_KEY = 'mt_orders_refresh_token';

@Injectable({ providedIn: 'root' })
export class OfflineAuthenticationService
  extends AbstractAuthenticationService
  implements AuthenticationService
{
  consumerNameTerminalLogin = (
    uid: number,
    data: {
      room: string;
      name?: string;
      first_name?: string;
      last_name?: string;
    },
  ) => {
    const consumers = JSON.parse(
      localStorage.getItem('consumers') || '[]',
    ) as RoomConsumer[];

    const selectedConsumer = consumers.filter(
      (consumer) =>
        consumer.room === data.room &&
        this.stringStartsWith(consumer.first_name, data.first_name) &&
        this.stringStartsWith(consumer.last_name, data.last_name),
    )[0];
    return of(selectedConsumer);
  };

  consumerTerminalLogin = (
    uid: number,
    data: {
      uuid: string;
    },
  ) => {
    const consumers = JSON.parse(
      localStorage.getItem('consumers') || '[]',
    ) as RoomConsumer[];

    const roomConsumers = consumers.filter(
      (consumer) => consumer.uuid === data.uuid,
    )[0];
    return of(roomConsumers);
  };

  loginToRoom = (
    uid: number,
    data: {
      rfid?: string;
      room?: string;
      name?: string;
      checkout?: string;
      custom_data?: CustomData;
    },
  ) => {
    const consumers = JSON.parse(
      localStorage.getItem('consumers') || '[]',
    ) as RoomConsumer[];
    const consumer = consumers.filter((cons) => cons.rfid === data.rfid)[0];

    // check if the consumer is undefined
    if (!consumer) {
      return throwError(
        () =>
          new HttpErrorResponse({
            error: {
              error: 'app.offline.errors.not-enabled',
            },
            status: 400,
          }),
      );
    }

    return of(consumer);
  };

  logoutFromRoom = (uid: number) => {
    return of({ message: 'OK' });
  };

  validateRoom = (
    uid: number,
    data: {
      room?: string;
    },
  ) => {
    const consumers = JSON.parse(
      localStorage.getItem('consumers') || '[]',
    ) as RoomConsumer[];

    const roomConsumers = consumers.filter(
      (consumer) => consumer.room === data.room,
    );

    if (!roomConsumers.length) {
      return throwError(
        () =>
          new HttpErrorResponse({
            error: {
              error: 'app.offline.errors.not-checked-in',
            },
            status: 400,
          }),
      );
    }

    return of(roomConsumers);
  };

  private stringStartsWith(str: string, checkStr: string) {
    return str.toLowerCase().startsWith(checkStr.toLowerCase());
  }
}
