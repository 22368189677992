import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import {
  Consistency,
  domStringMapToOrder,
  extractOrderItems,
  isOrderMatchingElement,
  Order,
  RepeatOrder,
  SetOrdersItem,
  Texture,
} from '../models/orders';
import { lightboxSelector, ManagableItem } from '../models/misc';
import { Consumer } from '../models/consumers';

@Directive({
  selector: '[runScripts]',
  standalone: true,
})
export class RunScriptsDirective implements OnInit, OnChanges {
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly elementRef = inject(ElementRef) as ElementRef<HTMLElement>;

  @Input() currentConsumer: ManagableItem | Consumer;
  @Input() currentOrders: Order[];
  @Input() currentOrdersRepeat: RepeatOrder[];
  @Input() isManager: boolean;
  @Input() lang: string;
  @Input() maximumOrdersPerSection = 0; // 0 means unlimited
  @Input() quantityFieldIncrement: number;
  @Input() showDate: boolean; // if true, it is a dated menu
  @Input() deselectedEvent: EventEmitter<string>;
  @Input() selectedEvent: EventEmitter<SetOrdersItem[]>;

  @Output() contentInit = new EventEmitter<void>();
  @Output() itemsSelected = new EventEmitter<void>();
  @Output() updateRepeatOrders = new EventEmitter<
    (RepeatOrder & { menudish_id: number })[]
  >();

  selectedUniqueIds = new Set<string>();

  ngOnChanges(changes: SimpleChanges): void {
    if ('currentOrders' in changes && this.currentOrders) {
      this.handleSelectedOrders(this.currentOrders);
    }
    if (
      this.isManager &&
      'currentOrdersRepeat' in changes &&
      this.currentOrdersRepeat
    ) {
      this.handleSelectedRepeatOrders(this.currentOrdersRepeat);
    }
  }

  ngOnInit(): void {
    // DOM must be rendered before running scripts (ngIf source on component is required)
    this.setupClickListeners();
  }

  handleSelectedRepeatOrders(repeatOrders: RepeatOrder[]): void {
    // repeated orders can only select from orderable items
    const allDishElements = Array.from(
      this.elementRef.nativeElement.querySelectorAll('[data-orderable=true]'),
    );

    interface MappedRepeat {
      dishLineElement: HTMLElement;
      repeatOrder: RepeatOrder;
    }

    const updatedRepeatOrders: (RepeatOrder & { menudish_id: number })[] = [];

    // map repeat orders to dish elements
    const mappedRepeats = repeatOrders
      .map((repeatOrder) => {
        const dishLineElement = allDishElements?.find((elem: HTMLElement) =>
          isOrderMatchingElement(
            domStringMapToOrder(elem.dataset),
            repeatOrder,
            this.showDate,
          ),
        );
        if (!dishLineElement) return undefined;
        return { dishLineElement, repeatOrder };
      })
      .filter((val) => !!val);

    // set data attributes on dish elements
    mappedRepeats.forEach((element: MappedRepeat) => {
      const { dishLineElement, repeatOrder } = element;
      const data = dishLineElement.dataset;
      if (data.dietRestriction !== 'true') {
        data['ordered'] = 'true';
      }
      if (dishLineElement.classList.contains('menu-item-disable')) {
        data['ordered'] = 'false';
      } else {
        const { uniqueId } = this.extractSectionsLevelsAndUniqueId(data);
        if (uniqueId) this.selectedUniqueIds.add(uniqueId);
        if (repeatOrder.description) {
          data['desc'] = 'true';
        }
      }

      // if it's a default item, copy the default values
      if (
        element.repeatOrder.default_order &&
        (this.currentConsumer?.texture ||
          data.setTexture ||
          this.currentConsumer?.consistency ||
          data.setConsistency ||
          data.setComment ||
          (data.minQuantity && +data.minQuantity > 1) ||
          this.quantityFieldIncrement !== 1 ||
          this.currentConsumer?.portion_size !== 1)
      ) {
        const newRepeatedOrder: RepeatOrder & { menudish_id: number } = {
          ...element.repeatOrder,
          menudish_id: +element.dishLineElement.dataset.id,
        };
        if (data.setTexture || this.currentConsumer?.texture)
          newRepeatedOrder['texture'] = data.setTexture
            ? (+data.setTexture as Texture)
            : this.currentConsumer.texture;
        if (data.setConsistency || this.currentConsumer?.consistency)
          newRepeatedOrder['consistency'] = data.setConsistency
            ? (+data.setConsistency as Consistency)
            : this.currentConsumer.consistency;
        if (data.setComment) {
          newRepeatedOrder['description'] = data.setComment;
          data['desc'] = 'true';
        }
        if (data.minQuantity || this.quantityFieldIncrement !== 1)
          newRepeatedOrder['quantity'] = data.minQuantity
            ? +data.minQuantity
            : this.quantityFieldIncrement;
        if (this.currentConsumer?.portion_size !== 1)
          newRepeatedOrder['portion_size'] = this.currentConsumer.portion_size;

        updatedRepeatOrders.push(newRepeatedOrder);
      }

      // if it's a repeated order, copy the saved values
      if (
        !element.repeatOrder.default_order &&
        (element.repeatOrder.description ||
          element.repeatOrder.quantity > 1 ||
          element.repeatOrder.portion_size !== 1 ||
          element.repeatOrder.consistency ||
          element.repeatOrder.texture)
      ) {
        const newRepeatedOrder: RepeatOrder & { menudish_id: number } = {
          ...element.repeatOrder,
          menudish_id: +element.dishLineElement.dataset.id,
        };
        updatedRepeatOrders.push(newRepeatedOrder);
      }
    });

    // emit item selected event, which sets the selection status
    if (mappedRepeats.length) this.itemsSelected.emit();

    // updates the selected items and clears repeated orders
    this.updateRepeatOrders.emit(updatedRepeatOrders);
  }

  selectById = (ord: Order, idData: number | number[]): boolean =>
    Array.isArray(idData)
      ? idData.includes(ord.menudish_id)
      : ord.menudish_id === idData;

  handleSelectedOrders(orders: Order[]): void {
    // Aggregated options are not highlighted, only the subsequent individual components are
    Array.from(
      this.elementRef.nativeElement.querySelectorAll(
        `[data-id][data-orderable=true]:not([data-aggregated="true"])`,
      ),
    ).forEach((dis: HTMLElement) => {
      const data = dis.dataset;
      const idData = data.id.includes(`,`)
        ? data.id.split(`,`).map((strId) => +strId)
        : +data.id;
      // TODO: could group orders by section/option to compare attributes exactly (multiple IDs, multiple names), instead of using .includes() for data attributes
      const order = orders.find(
        (ord) =>
          this.selectById(ord, idData) ||
          isOrderMatchingElement(
            domStringMapToOrder(dis.dataset),
            ord,
            this.showDate,
            true,
          ),
      );
      if ((idData || !!data.name) && order) {
        data[`ordered`] = `true`;
        const { uniqueId } = this.extractSectionsLevelsAndUniqueId(dis.dataset);
        if (uniqueId) this.selectedUniqueIds.add(uniqueId);
        if (order.description) {
          data[`desc`] = `true`;
        }
      }
    });
  }

  extractSectionsLevelsAndUniqueId(data: DOMStringMap): {
    section_level1: string;
    section_level2: string;
    section_level3: string;
    uniqueId: string;
  } {
    const section_level1 = data?.separatorLevel1;
    const section_level2 = data?.separatorLevel2;
    const section_level3 = data?.separatorLevel3;
    const uniqueId = `${section_level1 ?? ''}${section_level2 ?? ''}${
      section_level3 ?? ''
    }`;

    return { section_level1, section_level2, section_level3, uniqueId };
  }

  setupClickListenersDeselection(
    data: DOMStringMap,
    section_level1: string,
    section_level2: string,
    section_level3: string,
    maximum_selected: boolean,
    aggregatedItem: boolean,
  ): void {
    const elemToDeselect: NodeListOf<HTMLElement> =
      this.elementRef.nativeElement.querySelectorAll(
        this.getSelectorForUniqueId(
          section_level1,
          section_level2,
          section_level3,
          data[`option`],
          maximum_selected,
          'aggregated' in data,
        ),
      );
    elemToDeselect.forEach((el) => {
      if (
        (aggregatedItem && el.dataset[`aggregated`]) ||
        (!aggregatedItem && !el.dataset[`aggregated`])
      )
        delete el.dataset[`ordered`];
    });
  }

  setupClickListeners(): void {
    Array.from(this.elementRef.nativeElement.querySelectorAll(`link`)).forEach(
      (link) => {
        document.getElementsByTagName('head')[0].appendChild(link);
      },
    );
    Array.from(
      this.elementRef.nativeElement.querySelectorAll(`style[data-fonts]`),
    ).forEach((style) => {
      const newStyle = document.createElement('style');
      newStyle.appendChild(document.createTextNode(style.innerHTML));
      document.head.appendChild(newStyle);
    });
    Array.from(
      this.elementRef.nativeElement.querySelectorAll(`[data-orderable=true]`),
    ).forEach((dis: HTMLDivElement) => {
      dis.addEventListener(`click`, (ev) => {
        if (
          (ev.target as HTMLElement)?.parentElement?.dataset?.[lightboxSelector]
        )
          return;

        const data = dis.dataset;
        const classList = dis.classList;
        const { section_level1, section_level2, section_level3, uniqueId } =
          this.extractSectionsLevelsAndUniqueId(data);
        const ids = extractOrderItems([dis]);
        const ordered = data[`ordered`];

        // Keep a count of how many items are selected in this section
        let selectedInSection = 0;
        Array.from(
          this.elementRef.nativeElement.querySelectorAll(
            this.getSelectorForUniqueId(
              section_level1,
              section_level2,
              section_level3,
              data[`option`],
              !(
                selectedInSection >= this.maximumOrdersPerSection &&
                this.maximumOrdersPerSection > 0
              ),
              'aggregated' in data,
            ),
          ),
        ).forEach((item: HTMLElement) => {
          if (item.dataset[`ordered`] === `true`) selectedInSection += 1;
        });

        // If the item was already selected, unselect it
        if (ordered && ordered === `true`) {
          delete data[`ordered`];
          this.selectedUniqueIds.delete(uniqueId);
          this.deselectedEvent.emit(uniqueId);
          selectedInSection -= 1;
        } else {
          // If more items are selected than allowed, deselect the first one
          if (
            selectedInSection >= this.maximumOrdersPerSection &&
            this.maximumOrdersPerSection > 0 &&
            !(ordered && ordered === `true`)
          ) {
            const firstSelected: HTMLElement =
              this.elementRef.nativeElement.querySelector(
                this.getSelectorForUniqueId(
                  section_level1,
                  section_level2,
                  section_level3,
                  data[`option`],
                  true,
                  'aggregated' in data,
                ),
              );
            delete firstSelected?.dataset[`ordered`];
            this.deselectedEvent.emit(uniqueId);
            selectedInSection -= 1;
          }
          if (
            (this.maximumOrdersPerSection === 0 ||
              selectedInSection < this.maximumOrdersPerSection) &&
            !classList.contains('menu-item-disable')
          ) {
            data[`ordered`] = `true`;
            this.selectedUniqueIds.add(uniqueId);
            this.selectedEvent.emit(ids?.items);
          }
        }
        // handle aggregated items for hybrid ordertaking templates
        if (data[`aggregated`] === `true` || data[`excemptLimit`] === `true`) {
          this.setupClickListenersDeselection(
            data,
            section_level1,
            section_level2,
            section_level3,
            selectedInSection >= this.maximumOrdersPerSection &&
              this.maximumOrdersPerSection > 0,
            data[`excemptLimit`] === `true`,
          );
        }
        this.changeDetectorRef.detectChanges();
      });
    });

    this.contentInit.emit();
  }

  getSelectorForUniqueId(
    separatorLevel1: string,
    separatorLevel2: string,
    separatorLevel3: string,
    separatorOption: string,
    maximumReached: boolean,
    deselectComponents: boolean,
  ): string {
    let selector = '[data-orderable="true"][data-ordered="true"]';
    let i = 0;
    [separatorLevel1, separatorLevel2, separatorLevel3].forEach(
      (section: string) => {
        ++i;
        if (section) selector += `[data-separator-level${i}="${section}"]`;
      },
    );

    if (maximumReached) {
      if (!deselectComponents) selector += `:not([data-excempt-limit="true"])`;
      return selector;
    }

    selector += `[data-option="${separatorOption}"]`;
    if (!deselectComponents) {
      selector += `[data-aggregated="true"]`;
      return selector;
    }
    return selector;
  }
}
