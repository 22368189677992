import { Action, createReducer, on } from '@ngrx/store';

import { Diet } from '../../shared/models/diets';
import { setDiets } from './diets.actions';

export const dietsFeatureKey = 'diets';

export interface DietsState {
  items: Diet[];
}

export const initialState: DietsState = {
  items: null,
};

const _dietsReducer = createReducer(
  initialState,
  on(setDiets, (state, { payload: diets }) => ({
    ...state,
    items: diets,
  })),
);

export function dietsReducer(state: DietsState, action: Action) {
  return _dietsReducer(state, action);
}
