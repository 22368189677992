import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Course } from '../../shared/models/menus';
import {
  addCreatedCourse,
  removeDeletedCourse,
  setCourses,
  setCoursesDataLoading,
  setSelectedCourse,
  setUpdatedCourse,
} from './courses.actions';

export const coursesFeatureKey = 'courses';

export interface CoursesState {
  items: EntityState<Course>;
  loading: boolean;
  selectedCourse: Course;
}

export const adapter: EntityAdapter<Course> = createEntityAdapter<Course>();

export const initialState: CoursesState = {
  items: adapter.getInitialState({}),
  loading: false,
  selectedCourse: null,
};

const _coursesReducer = createReducer<CoursesState>(
  initialState,
  on(addCreatedCourse, (state, { payload }) => {
    return {
      ...state,
      items: adapter.setAll(
        [payload, ...adapter.getSelectors().selectAll(state.items)],
        state?.items,
      ),
    };
  }),
  on(setCourses, (state, { items }) => {
    return {
      ...state,
      items: adapter.setAll(items.results, state.items),
    };
  }),
  on(setCoursesDataLoading, (state, { loading }) => {
    return {
      ...state,
      loading,
    };
  }),
  on(removeDeletedCourse, (state, { courseId }) => {
    return {
      ...state,
      items: adapter.removeOne(courseId, state.items),
    };
  }),
  on(setSelectedCourse, (state, { course }) => {
    return {
      ...state,
      selectedCourse: course,
    };
  }),

  on(setUpdatedCourse, (state, { course, courseId }) => {
    return {
      ...state,
      items: adapter.updateOne({ id: courseId, changes: course }, state?.items),
    };
  }),
);

export function coursesReducer(state: CoursesState, action: Action) {
  return _coursesReducer(state, action);
}
