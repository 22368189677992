<section class="wrapper" [class.live-order-wrapper]="showLiveOrder$ | async">
  @if (showLiveOrder$ | async) {
  <win-live-order-view
    [lang]="gloablLanguage$ | async"
    [privacy]="terminalPrivacy$ | async"
    [orders]="orders$ | async"
    [ordersFetched]="ordersFetched$ | async"
    [ordersRemainingCount]="ordersRemainingCount$ | async"
    [ordersDoneCount]="ordersDoneCount$ | async"
    (fetchOrdersCount)="fetchOrdersCount()"
    (rfidChanged)="rfidChanged($event)"
  ></win-live-order-view>
  } @else if ((showSimpleLoginForm$ | async) && !unauthenticatedOrder) {
  <span>
    <h1 class="mat-h1">{{'login.welcome' | transloco}}</h1>
    <p class="subtitle">{{'login.title' | transloco}}</p>
  </span>
  <win-simple-login
    [spinnerState]="spinnerState$ | async"
    (login)="onLogin($event)"
  ></win-simple-login>
  } @else if (!unauthenticatedOrder) {
  <win-terminal-login
    (terminalLogin)="onTerminalLogin($event)"
    (validateRoom)="validateRoom($event)"
    (consumerTerminalLogin)="onConsumerTerminalLogin($event)"
    (consumerNameTerminalLogin)="onConsumerNameTerminalLogin($event)"
    [loading]="roomLoginBar$ | async"
    [roomDetailsLoading]="roomDetailsLoadingBar$ | async"
    [terminalType]="terminalType$ | async"
    [terminalPrivacy]="terminalPrivacy$ | async"
    [roomDetails]="roomDetails$ | async"
    [isManager]="isManager$ | async"
    [aggregatedOrderTakingEnabled]="aggregatedOrderTakingEnabled$ | async"
    [rfidEnabled]="rfidEnabled$ | async"
  ></win-terminal-login>
  } @else if (unauthenticatedOrder) {
  <win-unauthenticated-login
    [aggregatedOrderFeature]="unauthAggregatedOrderTakingEnabled$ | async"
    [unauthInvalidUrlMessage]="unauthInvalidUrlMessage$ | async"
    [spinnerState]="unauthSpinnerState$ | async"
  >
  </win-unauthenticated-login>
  }
</section>
