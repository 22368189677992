import { AsyncPipe } from '@angular/common';
import {
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { asyncScheduler } from 'rxjs';
import {
  filter,
  map,
  observeOn,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  selectGlobalLanguage,
  selectShowConsumerInfo,
  selectShowConsumerInfoDetail,
} from 'src/app/redux/global/global.selectors';
import {
  selectOfflineModeDate,
  selectOfflineModeValue,
} from 'src/app/redux/offline-mode/offline-mode.selectors';
import { selectQueryParams } from 'src/app/redux/router/router.selectors';
import { downloadReport, viewReport } from 'src/app/redux/user/user.actions';
import { Consumer, ManagableAccess } from 'src/app/shared/models/consumers';
import { Course, Menu } from 'src/app/shared/models/menus';
import {
  OrganisationFeatures,
  Report,
  ReportFormat,
  RoleChoice,
  UnauthConsumer,
  User,
} from 'src/app/shared/models/user';
import { getFormattedDate } from 'src/app/shared/utils.functions';

import { selectIsSimpleAuth } from '../../redux/authentication/authentication.selectors';
import { selectOrdersRouterExtras } from '../../redux/orders/orders.selectors';
import {
  selectConsumer,
  selectDownloadingReports,
  selectIsAgent,
  selectIsServiceUser,
  selectPersonalReports,
  selectShowThirdMenuCard,
  selectUser,
  selectUserStatusOrderUntil,
} from '../../redux/user/user.selectors';
import { UnknownManagableItem } from '../../shared/models/misc';
import { logout } from './../../redux/authentication/authentication.actions';
import {
  menuSelected,
  patchCheckoutDate,
  setMenus,
} from './../../redux/menus/menus.actions';
import {
  selectDailyMenus,
  selectMenus,
  selectSpecialMenus,
} from './../../redux/menus/menus.selectors';
import {
  selectEmailVerified,
  selectFeature,
  selectIsConsumer,
  selectIsKdsUser,
  selectIsManager,
  selectSimpleConsumer,
  selectIsTerminal,
  selectUserFullName,
} from './../../redux/user/user.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { MenusComponent } from './menus/menus.component';
import { BuildNewSkillsComponent } from './build-new-skills/build-new-skills.component';
import { DownloadReportComponent } from 'src/app/shared/components/download-report/download-report.component';
import { ConsumerReportsComponent } from './consumer-reports/consumer-reports.component';
import {
  deleteCourse,
  setSelectedCourse,
} from 'src/app/redux/courses/courses.actions';
import {
  selectCourses,
  selectIsCoursesLoading,
} from 'src/app/redux/courses/courses.selectors';

type Navigation = 'orders' | 'accesses' | 'consumers';

@Component({
  selector: 'win-menus-container',
  templateUrl: './menus.container.html',
  styleUrls: ['./menus.container.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    BuildNewSkillsComponent,
    ConsumerReportsComponent,
    DownloadReportComponent,
    MenusComponent,
    MatDatepickerModule,
    VerifyEmailComponent,
  ],
})
export class MenusContainer implements OnInit, OnDestroy {
  private readonly dateAdapter = inject<DateAdapter<Date>>(DateAdapter);
  private readonly destroyRef = inject(DestroyRef);
  private readonly router = inject(Router);
  private readonly store = inject(Store);

  menus$ = this.store.pipe(select(selectMenus)).pipe(
    tap((menus: Menu[]) => {
      if (!this.managableItem || !('checkin' in this.managableItem)) {
        const menuCheckin = menus?.filter((m) => m.checkin_day);
        const menuCheckout = menus?.filter((m) => m.checkout_day);
        if (menuCheckin?.length) {
          this.minDate = new Date(menuCheckin[0].date);
        }
        if (menuCheckout?.length) {
          this.maxDate = new Date(menuCheckout[0].date);
        }
      }
    }),
  );
  dailyMenus$ = this.store.pipe(select(selectDailyMenus));
  specialMenus$ = this.store.pipe(select(selectSpecialMenus));
  emailVerified$ = this.store.pipe(select(selectEmailVerified));
  globalLanguage$ = this.store.pipe(select(selectGlobalLanguage));
  downloadingReports$ = this.store.pipe(select(selectDownloadingReports));
  isSimpleAuth$ = this.store.pipe(select(selectIsSimpleAuth));
  isConsumer$ = this.store.pipe(select(selectIsConsumer));
  isKdsUser$ = this.store.pipe(select(selectIsKdsUser));
  isServiceUser$ = this.store.pipe(select(selectIsServiceUser));
  isOffline$ = this.store.select(selectOfflineModeValue);
  isAgent$ = this.store.pipe(select(selectIsAgent));
  isManager$ = this.store.pipe(
    select(selectIsManager),
    tap((isManager) => {
      this.isManager = isManager;
    }),
  );
  isTerminal$ = this.store.pipe(select(selectIsTerminal));
  offlineDate$ = this.store.pipe(
    select(selectOfflineModeDate),
    tap((date: string) => {
      if (date) {
        this.minDate = new Date(date);
        this.maxDate = new Date(date);
      }
    }),
  );
  userFullName$ = this.store.pipe(select(selectUserFullName));
  selectUserStatusOrderUntil$ = this.store.pipe(
    select(selectUserStatusOrderUntil),
  );
  selectUser$ = this.store.pipe(select(selectUser));
  terminalConsumer$ = this.store.pipe(
    select(selectConsumer),
    tap((consumer) => {
      this.terminalConsumer = consumer;
    }),
  );
  simpleConsumer$ = this.store.pipe(
    select(selectSimpleConsumer),
    tap((consumer) => {
      this.simpleConsumer = consumer;
    }),
  );
  staffOrdertaking$ = this.store.pipe(
    select(selectFeature(OrganisationFeatures.staff_ordertaking)),
  );
  showThirdCard$ = this.store.pipe(select(selectShowThirdMenuCard));
  managableItem$ = this.store.pipe(
    select(selectOrdersRouterExtras),
    tap((item) => {
      this.managableItem = item;
      if (item && 'checkin' in item) {
        const mItem = item as ManagableAccess;
        if (mItem.checkin) this.minDate = new Date(mItem.checkin);
        if (mItem.checkout) this.maxDate = new Date(mItem.checkout);
      }
    }),
  );
  backQueryParam$ = this.store.pipe(
    select(selectQueryParams),
    map((params) => params?.back as Navigation),
    filter((back) => !!back),
    tap((back: Navigation) => (this.goBackPage = back)),
  );
  selectShowConsumerInfo$ = this.store.pipe(select(selectShowConsumerInfo));
  selectShowConsumerInfoDetail$ = this.store.pipe(
    select(selectShowConsumerInfoDetail),
  );
  availableReports$ = this.store.pipe(select(selectPersonalReports));

  courses$ = this.store.pipe(
    select(selectCourses),
    withLatestFrom(this.globalLanguage$),
    map(([courses, lang]) =>
      courses.filter((course) => !!course[`url_${lang}`]),
    ),
  );
  loadingCourses$ = this.store.pipe(select(selectIsCoursesLoading));

  currentUser: User;
  isManager: boolean;
  DATE_FORMAT = 'yyyy-MM-dd';
  managableItem: UnknownManagableItem;
  maxDate: Date;
  minDate = new Date();
  orderUntil: number;
  goBackPage: 'accesses' | 'consumers' | 'orders';
  simpleConsumer: UnauthConsumer;
  terminalConsumer: Consumer;
  selectedDateHasOrders: string[] = [];

  @ViewChild(MatDatepickerInput)
  datepickerInput: MatDatepickerInput<Date>;

  @ViewChild(MatDatepicker)
  datepicker: MatDatepicker<Date>;

  ngOnInit(): void {
    this.selectUserStatusOrderUntil$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: number) => {
        this.orderUntil = res;
        const date = new Date();
        date.setDate(date.getDate() + res);
        if (!this.maxDate && res) this.maxDate = date;
      });
    this.selectUser$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user) => {
        this.currentUser = user;
      });
  }

  dateFilter = (d: Date | null): boolean => {
    const target =
      this.managableItem ||
      this.simpleConsumer ||
      this.terminalConsumer ||
      this.currentUser;

    const canOrderPast = (this.currentUser || (target as any)).location
      ? (this.currentUser || (target as any)).location_detail.allow_order_past
      : (this.currentUser || (target as any)).organisation.allow_order_past;

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const date = d || new Date();
    const maxDate = new Date(this.maxDate);
    const validDate = !isNaN(maxDate.valueOf());
    if (validDate) {
      return (
        date <= this.maxDate &&
        ((this.isManager && canOrderPast) || date >= yesterday)
      );
    } else {
      return date >= yesterday;
    }
  };

  logout(): void {
    this.store.dispatch(logout());
  }

  calculateMinDate(menu: Menu): Date {
    const target =
      this.managableItem ||
      this.simpleConsumer ||
      this.terminalConsumer ||
      this.currentUser;

    const canOrderPast = (this.currentUser || (target as any)).location
      ? (this.currentUser || (target as any)).location_detail.allow_order_past
      : (this.currentUser || (target as any)).organisation.allow_order_past;
    let minDate =
      this.isManager && canOrderPast
        ? new Date(this.currentUser.organisation.date_joined)
        : new Date();
    if (
      (!this.managableItem || !this.isManager) &&
      (!this.terminalConsumer || this.currentUser.role !== RoleChoice.CONSUMER)
    ) {
      minDate = new Date(menu.can_order_from);
    } else if (target.checkin) {
      const checkin = this.dateAdapter.parse(target.checkin, this.DATE_FORMAT);
      if (checkin > minDate) minDate = checkin;
    }

    return minDate;
  }

  /**
   * Select a dated menu (redirects to the select orders page)
   * @param menu the selected menu
   */
  selectMenu(menu: Menu): void {
    this.store.dispatch(menuSelected({ menu, item: this.managableItem }));
  }

  /**
   * Select a dateless menu (opens the datepicker)
   * @param menu the selected menu
   */
  selectMenuShowDatepicker(menu: Menu): void {
    console.log('menu', menu);
    this.selectedDateHasOrders = [...menu.list_orders];
    this.datepicker.datepickerInput.min = this.calculateMinDate(menu);
    if (menu.can_order_until)
      this.datepicker.datepickerInput.max = new Date(menu.can_order_until);

    this.datepicker.open();
    this.datepickerInput.dateChange
      .pipe(
        tap(() => this.datepicker.close()),
        takeUntil(this.datepicker.closedStream),
        observeOn(asyncScheduler),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(({ value }) => {
        this.store.dispatch(
          menuSelected({
            date: getFormattedDate(value),
            menu,
            item: this.managableItem,
          }),
        );
      });
  }

  changeCheckoutDate(payload: {
    data: Partial<ManagableAccess>;
    id: number;
    url: string;
  }): void {
    this.store.dispatch(patchCheckoutDate({ payload }));
  }

  goBack(): void {
    if (this.goBackPage) {
      this.router?.navigate([`manage`, this.goBackPage]);
    }
  }

  downloadReport({
    report,
    response,
  }: {
    report: Report;
    response?: ReportFormat;
  }): void {
    this.store.dispatch(downloadReport({ report, response }));
  }

  openReport(report: string): void {
    this.store.dispatch(viewReport({ report }));
  }

  deleteCourse(courseId: number): void {
    this.store.dispatch(deleteCourse({ courseId }));
  }

  editCourse(course: Course): void {
    this.store.dispatch(setSelectedCourse({ course }));
  }

  addNewCourse(): void {
    this.store.dispatch(setSelectedCourse({ course: null }));
  }

  dateClass = (date: Date) => {
    if (this._findDate(this.dateAdapter.format(date, 'yyyy-MM-dd'))) {
      return ['selected'];
    }
    return [];
  };

  private _findDate(date: string): boolean {
    return this.selectedDateHasOrders.includes(date);
  }

  ngOnDestroy(): void {
    this.store.dispatch(setMenus({ menus: null }));
  }
}
