<h2 mat-dialog-title class="mat-h2">{{'auto-logout.title' | transloco}}</h2>

<div mat-dialog-content class="wrapper">
  <h4
    class="mat-h4"
    [innerHTML]="'auto-logout.description' | transloco: {time: time}"
  ></h4>
</div>

<div mat-dialog-actions class="actions">
  <button mat-button mat-dialog-close class="win-button-light">
    {{'shared.exit' | transloco}}
  </button>
  <button mat-button [mat-dialog-close]="true" class="win-button">
    {{'shared.continue' | transloco}}
  </button>
</div>
