@if (external) {
  <a class="link-wrapper" [attr.href]="link">
    <div class="card mat-elevation-z2" [routerLink]="!external ? link : null">
      <img
        class="image"
        width="150"
        height="150"
        [ngSrc]="img"
        [alt]="title"
        priority
      />
      <div class="text">
        <a class="mat-body link"
          >{{ title }}<mat-icon>keyboard_arrow_right</mat-icon></a
        >
        <p class="mat-caption description">
          {{ description }}
        </p>
      </div>
    </div>
  </a>
} @else {
  <div class="card mat-elevation-z2" [routerLink]="!external ? link : null">
    <img
      class="image"
      width="150"
      height="150"
      [ngSrc]="img"
      [alt]="title"
      priority
    />
    <div class="text">
      <a class="mat-body link"
        >{{ title }}<mat-icon>keyboard_arrow_right</mat-icon></a
      >
      <p class="mat-caption description">
        {{ description }}
      </p>
    </div>
  </div>
}
