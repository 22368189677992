<div class="reports-wrapper">
  <h2 class="mat-h2">{{ 'menus.available-reports' | transloco }}</h2>
  <div class="reports">
    @for (report of availableReports; track report) {
      <win-download-report
        class="report"
        [format]="report.format"
        [loading]="downloadingReports?.includes(report.name)"
        [name]="report.name"
        (downloadReport)="downloadReport.emit({ report, response: $event })"
        (openReport)="openReport.emit(report.name)"
      ></win-download-report>
    }
  </div>
</div>
