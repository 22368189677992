import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class OfflineStorageService {
  keys: string[] = [];

  getValues(key: string, defaultValue?: any) {
    return JSON.parse(
      localStorage.getItem(key) || JSON.stringify(!!defaultValue),
    );
  }

  setValues(key: string, values: any) {
    if (!this.keys.includes(key)) {
      this.keys.push(key);
    }
    localStorage.setItem(key, JSON.stringify(values));
  }

  clear() {
    this.keys.forEach((key) => localStorage.removeItem(key));
  }
}
