import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import {
  catchError,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { DietsServiceBuilder } from '../../shared/services/diets/diets.builder';
import { Diet } from '../../shared/models/diets';
import { handleHttpError, setGlobalLanguage } from '../global/global.actions';
import { selectIsManagerOrAgentUser, selectUser } from '../user/user.selectors';
import { fetchDiets, setDiets } from './diets.actions';
import { selectGlobalLanguage } from '../global/global.selectors';

@Injectable()
export class DietsEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly dietsServiceBuilder = inject(DietsServiceBuilder);

  fetchDiets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDiets),
      withLatestFrom(this.store.select(selectGlobalLanguage)),
      switchMap(([, lang]) =>
        this.dietsServiceBuilder
          .getService()
          .fetchDiets({ ordering: `order,${lang}` })
          .pipe(
            mergeMap((res: Diet[]) => [setDiets({ payload: res })]),
            catchError((error: unknown) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  refetchDiets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setGlobalLanguage),
      withLatestFrom(
        this.store.select(selectUser),
        this.store.select(selectIsManagerOrAgentUser),
      ),
      switchMap(([{ payload: lang }, user, isManagerOrAgentUser]) => {
        if (user?.settings?.language !== lang && isManagerOrAgentUser) {
          return [fetchDiets()];
        }
        return [];
      }),
    ),
  );
}
