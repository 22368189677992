<div class="wrapper">
  <win-menu-card
    class="card"
    img="img/menusCard3.png"
    link="/manage/consumers"
    [description]="'menus.cards.consumers.description' | transloco"
    [title]="'menus.cards.consumers.title' | transloco"
  ></win-menu-card>

  @if (isManager) {
    <win-menu-card
      class="card"
      img="img/menusCard0.png"
      link="/manage/accesses"
      [description]="'menus.cards.accesses.description' | transloco"
      [title]="'menus.cards.accesses.title' | transloco"
    ></win-menu-card>
  }

  <win-menu-card
    class="card"
    img="img/menusCard1.png"
    link="/manage/orders"
    [description]="'menus.cards.orders.description' | transloco"
    [title]="'menus.cards.orders.title' | transloco"
  ></win-menu-card>

  @if (showThirdCard) {
    <win-menu-card
      class="card"
      img="img/menusCard2.png"
      [description]="'menus.cards.place_order.description' | transloco"
      [title]="'menus.cards.place_order.title' | transloco"
      (click)="scrollToMenus.emit()"
    ></win-menu-card>
  }

  @if (isKdsUser) {
    <win-menu-card
      class="card"
      img="img/menusCard4.png"
      [description]="'menus.cards.start_cooking.description' | transloco"
      [external]="true"
      [link]="kdsLink"
      [title]="'menus.cards.start_cooking.title' | transloco"
    ></win-menu-card>
  }

  @if (isServiceUser) {
    <win-menu-card
      class="card"
      img="img/menusCard5.png"
      [description]="'menus.cards.start_service.description' | transloco"
      [external]="true"
      [link]="serviceLink"
      [title]="'menus.cards.start_service.title' | transloco"
    ></win-menu-card>
  }
</div>
