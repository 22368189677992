import { AMQPChannel } from './amqp-channel.js';
import { AMQPError } from './amqp-error.js';
import { AMQPMessage } from './amqp-message.js';
import { AMQPView } from './amqp-view.js';
const VERSION = '3.1.1';
export class AMQPBaseClient {
  constructor(vhost, username, password, name, platform, frameMax = 4096, heartbeat = 0, channelMax = 0) {
    this.closed = true;
    this.channelMax = 0;
    this.logger = console;
    this.textEncoder = new TextEncoder();
    this.bufferPool = [];
    this.vhost = vhost;
    this.username = username;
    this.password = "";
    Object.defineProperty(this, 'password', {
      value: password,
      enumerable: false
    });
    if (name) this.name = name;
    if (platform) this.platform = platform;
    this.channels = [new AMQPChannel(this, 0)];
    this.onerror = error => this.logger?.error("amqp-client connection closed", error.message);
    if (frameMax < 4096) throw new Error("frameMax must be 4096 or larger");
    this.frameMax = frameMax;
    if (heartbeat < 0) throw new Error("heartbeat must be positive");
    this.heartbeat = heartbeat;
    if (channelMax && channelMax < 0) throw new Error("channelMax must be positive");
    this.channelMax = channelMax;
  }
  channel(id) {
    if (this.closed) return this.rejectClosed();
    if (id && id > 0) {
      const channel = this.channels[id];
      if (channel) return Promise.resolve(channel);
    }
    if (!id) id = this.channels.findIndex(ch => ch === undefined);
    if (id === -1) id = this.channels.length;
    if (id > this.channelMax && this.channelMax > 0) return Promise.reject(new AMQPError("Max number of channels reached", this));
    const channel = new AMQPChannel(this, id);
    this.channels[id] = channel;
    return channel.open();
  }
  close(reason = "", code = 200) {
    if (this.closed) return this.rejectClosed();
    this.closed = true;
    let j = 0;
    const frame = new AMQPView(new ArrayBuffer(512));
    frame.setUint8(j, 1);
    j += 1;
    frame.setUint16(j, 0);
    j += 2;
    frame.setUint32(j, 0);
    j += 4;
    frame.setUint16(j, 10);
    j += 2;
    frame.setUint16(j, 50);
    j += 2;
    frame.setUint16(j, code);
    j += 2;
    j += frame.setShortString(j, reason);
    frame.setUint16(j, 0);
    j += 2;
    frame.setUint16(j, 0);
    j += 2;
    frame.setUint8(j, 206);
    j += 1;
    frame.setUint32(3, j - 8);
    return new Promise((resolve, reject) => {
      this.send(new Uint8Array(frame.buffer, 0, j)).then(() => this.closePromise = [resolve, reject]).catch(reject);
    });
  }
  updateSecret(newSecret, reason) {
    let j = 0;
    const frame = new AMQPView(new ArrayBuffer(4096));
    frame.setUint8(j, 1);
    j += 1;
    frame.setUint16(j, 0);
    j += 2;
    frame.setUint32(j, 0);
    j += 4;
    frame.setUint16(j, 10);
    j += 2;
    frame.setUint16(j, 70);
    j += 2;
    j += frame.setLongString(j, newSecret);
    j += frame.setShortString(j, reason);
    frame.setUint8(j, 206);
    j += 1;
    frame.setUint32(3, j - 8);
    return new Promise((resolve, reject) => {
      this.send(new Uint8Array(frame.buffer, 0, j)).then(() => this.onUpdateSecretOk = resolve).catch(reject);
    });
  }
  rejectClosed() {
    return Promise.reject(new AMQPError("Connection closed", this));
  }
  rejectConnect(err) {
    if (this.connectPromise) {
      const [, reject] = this.connectPromise;
      delete this.connectPromise;
      reject(err);
    }
    this.closed = true;
    this.closeSocket();
  }
  parseFrames(view) {
    for (let i = 0; i < view.byteLength;) {
      let j = 0;
      const type = view.getUint8(i);
      i += 1;
      const channelId = view.getUint16(i);
      i += 2;
      const frameSize = view.getUint32(i);
      i += 4;
      try {
        const frameEnd = view.getUint8(i + frameSize);
        if (frameEnd !== 206) throw new AMQPError(`Invalid frame end ${frameEnd}, expected 206`, this);
      } catch (e) {
        throw new AMQPError(`Frame end out of range, frameSize=${frameSize}, pos=${i}, byteLength=${view.byteLength}`, this);
      }
      const channel = this.channels[channelId];
      if (!channel) {
        this.logger?.warn("AMQP channel", channelId, "not open");
        i += frameSize + 1;
        continue;
      }
      switch (type) {
        case 1:
          {
            const classId = view.getUint16(i);
            i += 2;
            const methodId = view.getUint16(i);
            i += 2;
            switch (classId) {
              case 10:
                {
                  switch (methodId) {
                    case 10:
                      {
                        i += frameSize - 4;
                        const startOk = new AMQPView(new ArrayBuffer(4096));
                        startOk.setUint8(j, 1);
                        j += 1;
                        startOk.setUint16(j, 0);
                        j += 2;
                        startOk.setUint32(j, 0);
                        j += 4;
                        startOk.setUint16(j, 10);
                        j += 2;
                        startOk.setUint16(j, 11);
                        j += 2;
                        const clientProps = {
                          connection_name: this.name || undefined,
                          product: "amqp-client.js",
                          information: "https://github.com/cloudamqp/amqp-client.js",
                          version: VERSION,
                          platform: this.platform,
                          capabilities: {
                            "authentication_failure_close": true,
                            "basic.nack": true,
                            "connection.blocked": true,
                            "consumer_cancel_notify": true,
                            "exchange_exchange_bindings": true,
                            "per_consumer_qos": true,
                            "publisher_confirms": true
                          }
                        };
                        j += startOk.setTable(j, clientProps);
                        j += startOk.setShortString(j, "PLAIN");
                        const response = `\u0000${this.username}\u0000${this.password}`;
                        j += startOk.setLongString(j, response);
                        j += startOk.setShortString(j, "");
                        startOk.setUint8(j, 206);
                        j += 1;
                        startOk.setUint32(3, j - 8);
                        this.send(new Uint8Array(startOk.buffer, 0, j)).catch(this.rejectConnect);
                        break;
                      }
                    case 30:
                      {
                        const channelMax = view.getUint16(i);
                        i += 2;
                        const frameMax = view.getUint32(i);
                        i += 4;
                        const heartbeat = view.getUint16(i);
                        i += 2;
                        this.channelMax = this.channelMax === 0 ? channelMax : Math.min(this.channelMax, channelMax);
                        this.frameMax = this.frameMax === 0 ? frameMax : Math.min(this.frameMax, frameMax);
                        this.heartbeat = this.heartbeat === 0 ? 0 : Math.min(this.heartbeat, heartbeat);
                        const tuneOk = new AMQPView(new ArrayBuffer(20));
                        tuneOk.setUint8(j, 1);
                        j += 1;
                        tuneOk.setUint16(j, 0);
                        j += 2;
                        tuneOk.setUint32(j, 12);
                        j += 4;
                        tuneOk.setUint16(j, 10);
                        j += 2;
                        tuneOk.setUint16(j, 31);
                        j += 2;
                        tuneOk.setUint16(j, this.channelMax);
                        j += 2;
                        tuneOk.setUint32(j, this.frameMax);
                        j += 4;
                        tuneOk.setUint16(j, this.heartbeat);
                        j += 2;
                        tuneOk.setUint8(j, 206);
                        j += 1;
                        this.send(new Uint8Array(tuneOk.buffer, 0, j)).catch(this.rejectConnect);
                        j = 0;
                        const open = new AMQPView(new ArrayBuffer(512));
                        open.setUint8(j, 1);
                        j += 1;
                        open.setUint16(j, 0);
                        j += 2;
                        open.setUint32(j, 0);
                        j += 4;
                        open.setUint16(j, 10);
                        j += 2;
                        open.setUint16(j, 40);
                        j += 2;
                        j += open.setShortString(j, this.vhost);
                        open.setUint8(j, 0);
                        j += 1;
                        open.setUint8(j, 0);
                        j += 1;
                        open.setUint8(j, 206);
                        j += 1;
                        open.setUint32(3, j - 8);
                        this.send(new Uint8Array(open.buffer, 0, j)).catch(this.rejectConnect);
                        break;
                      }
                    case 41:
                      {
                        i += 1;
                        this.closed = false;
                        const promise = this.connectPromise;
                        if (promise) {
                          const [resolve] = promise;
                          delete this.connectPromise;
                          resolve(this);
                        }
                        break;
                      }
                    case 50:
                      {
                        const code = view.getUint16(i);
                        i += 2;
                        const [text, strLen] = view.getShortString(i);
                        i += strLen;
                        const classId = view.getUint16(i);
                        i += 2;
                        const methodId = view.getUint16(i);
                        i += 2;
                        this.logger?.debug("connection closed by server", code, text, classId, methodId);
                        const msg = `connection closed: ${text} (${code})`;
                        const err = new AMQPError(msg, this);
                        this.channels.forEach(ch => ch.setClosed(err));
                        this.channels = [new AMQPChannel(this, 0)];
                        const closeOk = new AMQPView(new ArrayBuffer(12));
                        closeOk.setUint8(j, 1);
                        j += 1;
                        closeOk.setUint16(j, 0);
                        j += 2;
                        closeOk.setUint32(j, 4);
                        j += 4;
                        closeOk.setUint16(j, 10);
                        j += 2;
                        closeOk.setUint16(j, 51);
                        j += 2;
                        closeOk.setUint8(j, 206);
                        j += 1;
                        this.send(new Uint8Array(closeOk.buffer, 0, j)).catch(err => this.logger?.warn("Error while sending Connection#CloseOk", err));
                        this.onerror(err);
                        this.rejectConnect(err);
                        this.onUpdateSecretOk?.();
                        break;
                      }
                    case 51:
                      {
                        this.channels.forEach(ch => ch.setClosed());
                        this.channels = [new AMQPChannel(this, 0)];
                        const promise = this.closePromise;
                        if (promise) {
                          const [resolve] = promise;
                          delete this.closePromise;
                          resolve();
                          this.closeSocket();
                        }
                        break;
                      }
                    case 60:
                      {
                        const [reason, len] = view.getShortString(i);
                        i += len;
                        this.logger?.warn("AMQP connection blocked:", reason);
                        this.blocked = reason;
                        break;
                      }
                    case 61:
                      {
                        this.logger?.info("AMQP connection unblocked");
                        delete this.blocked;
                        break;
                      }
                    case 71:
                      {
                        console.info("AMQP connection update secret ok");
                        this.onUpdateSecretOk?.();
                        delete this.onUpdateSecretOk;
                        break;
                      }
                    default:
                      i += frameSize - 4;
                      this.logger?.error("unsupported class/method id", classId, methodId);
                  }
                  break;
                }
              case 20:
                {
                  switch (methodId) {
                    case 11:
                      {
                        i += 4;
                        channel.resolveRPC(channel);
                        break;
                      }
                    case 21:
                      {
                        const active = view.getUint8(i) !== 0;
                        i += 1;
                        channel.resolveRPC(active);
                        break;
                      }
                    case 40:
                      {
                        const code = view.getUint16(i);
                        i += 2;
                        const [text, strLen] = view.getShortString(i);
                        i += strLen;
                        const classId = view.getUint16(i);
                        i += 2;
                        const methodId = view.getUint16(i);
                        i += 2;
                        this.logger?.debug("channel", channelId, "closed", code, text, classId, methodId);
                        const msg = `channel ${channelId} closed: ${text} (${code})`;
                        const err = new AMQPError(msg, this);
                        channel.setClosed(err);
                        delete this.channels[channelId];
                        const closeOk = new AMQPView(new ArrayBuffer(12));
                        closeOk.setUint8(j, 1);
                        j += 1;
                        closeOk.setUint16(j, channelId);
                        j += 2;
                        closeOk.setUint32(j, 4);
                        j += 4;
                        closeOk.setUint16(j, 20);
                        j += 2;
                        closeOk.setUint16(j, 41);
                        j += 2;
                        closeOk.setUint8(j, 206);
                        j += 1;
                        this.send(new Uint8Array(closeOk.buffer, 0, j)).catch(err => this.logger?.error("Error while sending Channel#closeOk", err));
                        break;
                      }
                    case 41:
                      {
                        channel.setClosed();
                        delete this.channels[channelId];
                        channel.resolveRPC();
                        break;
                      }
                    default:
                      i += frameSize - 4;
                      this.logger?.error("unsupported class/method id", classId, methodId);
                  }
                  break;
                }
              case 40:
                {
                  switch (methodId) {
                    case 11:
                    case 21:
                    case 31:
                    case 51:
                      {
                        channel.resolveRPC();
                        break;
                      }
                    default:
                      i += frameSize - 4;
                      this.logger?.error("unsupported class/method id", classId, methodId);
                  }
                  break;
                }
              case 50:
                {
                  switch (methodId) {
                    case 11:
                      {
                        const [name, strLen] = view.getShortString(i);
                        i += strLen;
                        const messageCount = view.getUint32(i);
                        i += 4;
                        const consumerCount = view.getUint32(i);
                        i += 4;
                        channel.resolveRPC({
                          name,
                          messageCount,
                          consumerCount
                        });
                        break;
                      }
                    case 21:
                      {
                        channel.resolveRPC();
                        break;
                      }
                    case 31:
                      {
                        const messageCount = view.getUint32(i);
                        i += 4;
                        channel.resolveRPC({
                          messageCount
                        });
                        break;
                      }
                    case 41:
                      {
                        const messageCount = view.getUint32(i);
                        i += 4;
                        channel.resolveRPC({
                          messageCount
                        });
                        break;
                      }
                    case 51:
                      {
                        channel.resolveRPC();
                        break;
                      }
                    default:
                      i += frameSize - 4;
                      this.logger?.error("unsupported class/method id", classId, methodId);
                  }
                  break;
                }
              case 60:
                {
                  switch (methodId) {
                    case 11:
                      {
                        channel.resolveRPC();
                        break;
                      }
                    case 21:
                      {
                        const [consumerTag, len] = view.getShortString(i);
                        i += len;
                        channel.resolveRPC(consumerTag);
                        break;
                      }
                    case 30:
                      {
                        const [consumerTag, len] = view.getShortString(i);
                        i += len;
                        const noWait = view.getUint8(i) === 1;
                        i += 1;
                        const consumer = channel.consumers.get(consumerTag);
                        if (consumer) {
                          consumer.setClosed(new AMQPError("Consumer cancelled by the server", this));
                          channel.consumers.delete(consumerTag);
                        }
                        if (!noWait) {
                          const frame = new AMQPView(new ArrayBuffer(512));
                          frame.setUint8(j, 1);
                          j += 1;
                          frame.setUint16(j, channel.id);
                          j += 2;
                          frame.setUint32(j, 0);
                          j += 4;
                          frame.setUint16(j, 60);
                          j += 2;
                          frame.setUint16(j, 31);
                          j += 2;
                          j += frame.setShortString(j, consumerTag);
                          frame.setUint8(j, 206);
                          j += 1;
                          frame.setUint32(3, j - 8);
                          this.send(new Uint8Array(frame.buffer, 0, j));
                        }
                        break;
                      }
                    case 31:
                      {
                        const [consumerTag, len] = view.getShortString(i);
                        i += len;
                        channel.resolveRPC(consumerTag);
                        break;
                      }
                    case 50:
                      {
                        const code = view.getUint16(i);
                        i += 2;
                        const [text, len] = view.getShortString(i);
                        i += len;
                        const [exchange, exchangeLen] = view.getShortString(i);
                        i += exchangeLen;
                        const [routingKey, routingKeyLen] = view.getShortString(i);
                        i += routingKeyLen;
                        const message = new AMQPMessage(channel);
                        message.exchange = exchange;
                        message.routingKey = routingKey;
                        message.replyCode = code;
                        message.replyText = text;
                        channel.returned = message;
                        break;
                      }
                    case 60:
                      {
                        const [consumerTag, consumerTagLen] = view.getShortString(i);
                        i += consumerTagLen;
                        const deliveryTag = view.getUint64(i);
                        i += 8;
                        const redelivered = view.getUint8(i) === 1;
                        i += 1;
                        const [exchange, exchangeLen] = view.getShortString(i);
                        i += exchangeLen;
                        const [routingKey, routingKeyLen] = view.getShortString(i);
                        i += routingKeyLen;
                        const message = new AMQPMessage(channel);
                        message.consumerTag = consumerTag;
                        message.deliveryTag = deliveryTag;
                        message.exchange = exchange;
                        message.routingKey = routingKey;
                        message.redelivered = redelivered;
                        channel.delivery = message;
                        break;
                      }
                    case 71:
                      {
                        const deliveryTag = view.getUint64(i);
                        i += 8;
                        const redelivered = view.getUint8(i) === 1;
                        i += 1;
                        const [exchange, exchangeLen] = view.getShortString(i);
                        i += exchangeLen;
                        const [routingKey, routingKeyLen] = view.getShortString(i);
                        i += routingKeyLen;
                        const messageCount = view.getUint32(i);
                        i += 4;
                        const message = new AMQPMessage(channel);
                        message.deliveryTag = deliveryTag;
                        message.redelivered = redelivered;
                        message.exchange = exchange;
                        message.routingKey = routingKey;
                        message.messageCount = messageCount;
                        channel.getMessage = message;
                        break;
                      }
                    case 72:
                      {
                        const [, len] = view.getShortString(i);
                        i += len;
                        channel.resolveRPC(null);
                        break;
                      }
                    case 80:
                      {
                        const deliveryTag = view.getUint64(i);
                        i += 8;
                        const multiple = view.getUint8(i) === 1;
                        i += 1;
                        channel.publishConfirmed(deliveryTag, multiple, false);
                        break;
                      }
                    case 111:
                      {
                        channel.resolveRPC();
                        break;
                      }
                    case 120:
                      {
                        const deliveryTag = view.getUint64(i);
                        i += 8;
                        const multiple = view.getUint8(i) === 1;
                        i += 1;
                        channel.publishConfirmed(deliveryTag, multiple, true);
                        break;
                      }
                    default:
                      i += frameSize - 4;
                      this.logger?.error("unsupported class/method id", classId, methodId);
                  }
                  break;
                }
              case 85:
                {
                  switch (methodId) {
                    case 11:
                      {
                        channel.confirmId = 1;
                        channel.resolveRPC();
                        break;
                      }
                    default:
                      i += frameSize - 4;
                      this.logger?.error("unsupported class/method id", classId, methodId);
                  }
                  break;
                }
              case 90:
                {
                  switch (methodId) {
                    case 11:
                    case 21:
                    case 31:
                      {
                        channel.resolveRPC();
                        break;
                      }
                    default:
                      i += frameSize - 4;
                      this.logger?.error("unsupported class/method id", classId, methodId);
                  }
                  break;
                }
              default:
                i += frameSize - 2;
                this.logger?.error("unsupported class id", classId);
            }
            break;
          }
        case 2:
          {
            i += 4;
            const bodySize = view.getUint64(i);
            i += 8;
            const [properties, propLen] = view.getProperties(i);
            i += propLen;
            const message = channel.delivery || channel.getMessage || channel.returned;
            if (message) {
              message.bodySize = bodySize;
              message.properties = properties;
              message.body = new Uint8Array(bodySize);
              if (bodySize === 0) channel.onMessageReady(message);
            } else {
              this.logger?.warn("Header frame but no message");
            }
            break;
          }
        case 3:
          {
            const message = channel.delivery || channel.getMessage || channel.returned;
            if (message && message.body) {
              const bodyPart = new Uint8Array(view.buffer, view.byteOffset + i, frameSize);
              message.body.set(bodyPart, message.bodyPos);
              message.bodyPos += frameSize;
              i += frameSize;
              if (message.bodyPos === message.bodySize) channel.onMessageReady(message);
            } else {
              this.logger?.warn("Body frame but no message");
            }
            break;
          }
        case 8:
          {
            const heartbeat = new Uint8Array([8, 0, 0, 0, 0, 0, 0, 206]);
            this.send(heartbeat).catch(err => this.logger?.warn("Error while sending heartbeat", err));
            break;
          }
        default:
          this.logger?.error("invalid frame type:", type);
          i += frameSize;
      }
      i += 1;
    }
  }
}
