import { Injectable } from '@angular/core';
import {
  ConsumerNameTerminalLoginForm,
  RoomLoginForm,
} from 'src/app/shared/models/authentication';
import { Consumer } from 'src/app/shared/models/consumers';
import { User } from 'src/app/shared/models/user';

import { RoomConsumer } from '../../models/user';
import { AuthenticationService } from './authentication.service';
import { AbstractAuthenticationService } from './abstract.authentication.service';

export const TOKEN_KEY = 'mt_orders_access_token';
export const REFRESH_TOKEN_KEY = 'mt_orders_refresh_token';

@Injectable({ providedIn: 'root' })
export class OnlineAuthenticationService
  extends AbstractAuthenticationService
  implements AuthenticationService
{
  consumerNameTerminalLogin = (
    uid: number,
    data: ConsumerNameTerminalLoginForm,
  ) =>
    this.utilsService.post<ConsumerNameTerminalLoginForm, Consumer>(
      this.roomSessionLoginEP(uid),
      data,
    );

  consumerTerminalLogin = (uid: number, data: { uuid: string }) =>
    this.utilsService.post<{ uuid: string }, Consumer | User>(
      this.roomLoginEP(uid),
      data,
    );

  loginToRoom = (uid: number, data: RoomLoginForm) =>
    this.utilsService.post<RoomLoginForm, Consumer | User>(
      this.roomLoginEP(uid),
      data,
    );

  logoutFromRoom = (uid: number) =>
    this.utilsService.post<Record<string, never>, { message: string }>(
      this.logoutFromRoomEP(uid),
      {},
    );

  validateRoom = (uid: number, data: { room?: string }) =>
    this.utilsService.post<
      { room?: string },
      { message: string } | RoomConsumer[]
    >(this.validateRoomEP(uid), data);
}
