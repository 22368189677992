import { Injectable, inject } from '@angular/core';
import { API_CONFIG } from 'src/app/app.config';
import { Consumer } from 'src/app/shared/models/consumers';
import {
  FetchOrdersParams,
  GeneralRepeatOrdersData,
  Order,
  RepeatOrder,
  RepeatOrderData,
  ServiceStatus,
} from 'src/app/shared/models/orders';
import { User } from 'src/app/shared/models/user';

import { OrdersService } from './orders.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Injectable({ providedIn: 'root' })
export class OnlineOrdersService implements OrdersService {
  private readonly utilsService = inject(UtilsService);

  private readonly ordersEP = `${API_CONFIG.orderTakingApi}/orders/`;
  private readonly repeatEP = `${API_CONFIG.orderTakingApi}/orders_repeat/`;
  private readonly getUserEP = (id: number) =>
    `${API_CONFIG.orderTakingApi}/users/${id}/`;
  private readonly getConsumerEP = (id: number) =>
    `${API_CONFIG.orderTakingApi}/consumers/${id}/`;

  private readonly getProductSheetEP = (token: string) =>
    `${API_CONFIG.mealPlanningApi}/public/products/${token}/`;

  getUser = (id: number) => this.utilsService.get<User>(this.getUserEP(id));

  getConsumer = (id: number) =>
    this.utilsService.get<Consumer>(this.getConsumerEP(id));

  fetchMenuSource = (url: string, params = {}) =>
    this.utilsService.get<string>(url, params, 'text');

  fetchProductSheetSource = (token: string, params = {}) =>
    this.utilsService.get<string>(
      this.getProductSheetEP(token),
      params,
      'text',
    );

  createRepeatOrder = (data: RepeatOrderData) =>
    this.utilsService.post<RepeatOrderData, RepeatOrder>(this.repeatEP, data);

  fetchOrders = (params: Partial<FetchOrdersParams>) =>
    this.utilsService.get<Order[]>(this.ordersEP, params);

  fetchRepeatOrders = (params: Partial<FetchOrdersParams>) =>
    this.utilsService.get<RepeatOrder[]>(this.repeatEP, params);

  setGeneralRepeatOrders = (url: string, data: GeneralRepeatOrdersData) =>
    this.utilsService.post<GeneralRepeatOrdersData, RepeatOrder[]>(url, data);

  fetchOrdersCount = (params: Partial<FetchOrdersParams>) =>
    this.utilsService.get<{ service_status: ServiceStatus; count: number }[]>(
      this.ordersEP + 'count_status/',
      params,
    );
}
