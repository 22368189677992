import { AMQPError } from './amqp-error.js';
export class AMQPConsumer {
  constructor(channel, tag, onMessage) {
    this.closed = false;
    this.channel = channel;
    this.tag = tag;
    this.onMessage = onMessage;
  }
  wait(timeout) {
    if (this.closedError) return Promise.reject(this.closedError);
    if (this.closed) return Promise.resolve();
    return new Promise((resolve, reject) => {
      this.resolveWait = resolve;
      this.rejectWait = reject;
      if (timeout) {
        const onTimeout = () => reject(new AMQPError("Timeout", this.channel.connection));
        this.timeoutId = setTimeout(onTimeout, timeout);
      }
    });
  }
  cancel() {
    return this.channel.basicCancel(this.tag);
  }
  setClosed(err) {
    this.closed = true;
    if (err) this.closedError = err;
    if (this.timeoutId) clearTimeout(this.timeoutId);
    if (err) {
      if (this.rejectWait) this.rejectWait(err);
    } else {
      if (this.resolveWait) this.resolveWait();
    }
  }
}
