@if (isDownloading$ | async) {
  <h2 mat-dialog-title>
    {{ 'app.offline.enable.title-in-progress' | transloco }}
  </h2>
  <ng-container *ngTemplateOutlet="spinner"></ng-container>
  <div mat-dialog-actions>
    <p>{{ 'app.offline.enable.download' | transloco }}</p>
  </div>
}

@if (isSubmitting$ | async) {
  <h2 mat-dialog-title>
    {{ 'app.offline.disable.title-in-progress' | transloco }}
  </h2>
  <ng-container *ngTemplateOutlet="spinner"></ng-container>
  <div mat-dialog-actions>
    <p>
      {{
        'app.offline.disable.transmit'
          | transloco: { number: offlineOrderCount$ | async }
      }}
    </p>
  </div>
}

<ng-template #spinner>
  <div mat-dialog-content class="content">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
