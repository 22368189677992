import { inject } from '@angular/core';
import { API_CONFIG } from 'src/app/app.config';
import { UtilsService } from '../utils.service';
import {
  LoginSuccess,
  ResetSuccess,
  VerifySuccess,
  ConfirmPasswordResetForm,
  LoginForm,
  RegistrationForm,
  GenericName,
} from '../../models/authentication';
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from './authentication.service';
import { InvitationResult, Organisation } from '../../models/user';

export abstract class AbstractAuthenticationService {
  protected readonly utilsService = inject(UtilsService);

  protected readonly loginEP = `${API_CONFIG.orderTakingApi}/rest-auth/login/`;
  protected readonly emailVerificationEP = `${API_CONFIG.orderTakingApi}/users/verify_email/`;
  protected readonly registrationEP = `${API_CONFIG.orderTakingApi}/rest-auth/registration/`;
  protected readonly resetPasswordEP = `${API_CONFIG.orderTakingApi}/rest-auth/password/reset/`;
  protected readonly confirmEmailEP = `${API_CONFIG.orderTakingApi}/rest-auth/registration/verify-email/`;
  protected readonly confirmPasswordResetEP = `${API_CONFIG.orderTakingApi}/rest-auth/password/reset/confirm/`;
  protected readonly resendEmailConfirmationEP = `${API_CONFIG.orderTakingApi}/rest-auth/registration/resend_email_confirmation/`;
  protected readonly refreshTokenEP = `${API_CONFIG.orderTakingApi}/rest-auth/token/refresh/`;
  protected readonly checkInvitationEP = `${API_CONFIG.orderTakingApi}/invitations/check_invitation/`;

  protected readonly verifyPasswordEP = (userUrl: string) =>
    `${userUrl}verify_password/`;
  protected readonly roomLoginEP = (uid: number) =>
    `${API_CONFIG.orderTakingApi}/users/${uid}/login_terminal_session/`;
  protected readonly retrieveUuidEP = (key: string, auth_room: string) =>
    `${API_CONFIG.orderTakingApi}/consumers/retrieve_uuid/?key=${key}&auth_room=${auth_room}`;
  protected readonly roomSessionLoginEP = (uid: number) =>
    `${API_CONFIG.orderTakingApi}/users/${uid}/login_room_session/`;
  protected readonly validateRoomEP = (uid: number) =>
    `${API_CONFIG.orderTakingApi}/users/${uid}/validate_room/`;
  protected readonly logoutFromRoomEP = (uid: number) =>
    `${API_CONFIG.orderTakingApi}/users/${uid}/logout_terminal_session/`;
  protected readonly validateKeyEP = (key: string) =>
    `${API_CONFIG.orderTakingApi}/consumers/validate_organisation/?key=${key}`;

  checkInvitation = (key: string) =>
    this.utilsService.post<{ key: string }, InvitationResult>(
      this.checkInvitationEP,
      { key },
    );

  validateKey = (key: string) =>
    this.utilsService.post<Record<string, never>, Organisation>(
      this.validateKeyEP(key),
      {},
    );

  login = (data: LoginForm) =>
    this.utilsService.post<LoginForm, LoginSuccess>(this.loginEP, data);

  refreshToken = (refreshToken: string) =>
    this.utilsService.post<
      { refresh: string },
      { access: string; refresh: string }
    >(this.refreshTokenEP, {
      refresh: refreshToken,
    });

  saveToken = (data: LoginSuccess | ResetSuccess) => {
    this.saveAccessToken(data?.access);
    localStorage.setItem(REFRESH_TOKEN_KEY, data.refresh);
  };

  saveAccessToken = (access: string, refresh?: string) => {
    localStorage.setItem(TOKEN_KEY, access);
    if (refresh) localStorage.setItem(REFRESH_TOKEN_KEY, refresh);
  };

  removeToken = () => {
    localStorage.clear();
  };

  getToken = (): string | undefined => localStorage.getItem(TOKEN_KEY);

  getRefreshToken = (): string | undefined =>
    localStorage.getItem(REFRESH_TOKEN_KEY);

  // checks email's validity before registration
  checkEmail = (email: string) =>
    this.utilsService.post<{ email: string }, { message: string }>(
      this.emailVerificationEP,
      { email },
    );

  confirmEmail = (key: string) =>
    this.utilsService.post<{ key: string }, VerifySuccess>(
      this.confirmEmailEP,
      { key },
    );

  confirmPasswordReset = (data: ConfirmPasswordResetForm) =>
    this.utilsService.post<ConfirmPasswordResetForm, LoginSuccess>(
      this.confirmPasswordResetEP,
      data,
    );

  register = (data: RegistrationForm) =>
    this.utilsService.post<RegistrationForm, LoginSuccess>(
      this.registrationEP,
      data,
    );

  resendEmailConfirmation = () =>
    this.utilsService.post<Record<string, never>, { message: string }>(
      this.resendEmailConfirmationEP,
      {},
    );

  resetPassword = (data: { email: string }) =>
    this.utilsService.post<{ email: string }, { detail: string }>(
      this.resetPasswordEP,
      data,
    );

  retrieveUuid = (consumerName: GenericName, key: string, auth_room: string) =>
    this.utilsService.post<GenericName, { uuid: string }>(
      this.retrieveUuidEP(key, auth_room),
      consumerName,
    );

  verifyPassword = (userUrl: string, password: string) =>
    this.utilsService.post<{ password: string }, { message: string }>(
      this.verifyPasswordEP(userUrl),
      {
        password,
      },
    );
}
