import { Pipe, PipeTransform, inject } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'yearlessDate',
  standalone: true,
})
export class YearlessDatePipe implements PipeTransform {
  private readonly datePipe = inject(DatePipe);

  transform(
    value: string,
    format: string,
    timezone: string,
    locale: string,
  ): string {
    const fullDate = this.datePipe.transform(value, format, timezone, locale);
    if (!fullDate) {
      return '';
    }

    const parts = fullDate.split(' ');
    if (parts.length < 3) {
      return fullDate;
    }

    // Assume the year is the part that is purely numeric and has 4 digits
    const yearPartIndex = parts.findIndex((part) => /^\d{4}$/.test(part));
    if (yearPartIndex === -1) {
      return fullDate;
    }

    parts.splice(yearPartIndex, 1);
    let result = parts.join(' ');
    if (result.endsWith(',')) {
      result = result.slice(0, -1);
    }
    if (result.endsWith(' de')) {
      result = result.slice(0, -4);
    }
    return result.trim();
  }
}
