import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoPipe } from '@jsverse/transloco';
import { ReportFormat, SupportedReportFormats } from '../../models/user';

@Component({
  selector: 'win-download-report',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    TranslocoPipe,
  ],
})
export class DownloadReportComponent {
  @Input() loading: boolean;
  @Input() format: SupportedReportFormats;
  @Input() hideDigital = false;
  @Input() name: string;
  @Input() nameItems: string;
  @Input() totalCount: number;
  @Input() innerHTML: string;

  @Output() downloadReport = new EventEmitter<ReportFormat | undefined>();
  @Output() openReport = new EventEmitter<void>();
}
