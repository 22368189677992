import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { DownloadReportComponent } from 'src/app/shared/components/download-report/download-report.component';
import { Report, ReportFormat } from 'src/app/shared/models/user';

@Component({
  selector: 'win-consumer-reports',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DownloadReportComponent, TranslocoModule],
  templateUrl: './consumer-reports.component.html',
  styleUrl: './consumer-reports.component.scss',
})
export class ConsumerReportsComponent {
  @Input() availableReports: Report[];
  @Input() downloadingReports: string[];

  @Output() downloadReport = new EventEmitter<{
    report: Report;
    response?: ReportFormat;
  }>();
  @Output() openReport = new EventEmitter<string>();
}
